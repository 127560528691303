<template>
  <modal-dialog
    id="waitForProcessDlg"
    :is-open="true"
    :title="title"
    :show-footer="false"
    :show-close-header="false"
  >
    <div class="message-area">
      <div v-if="message" class="message-text">
        {{ message }}
      </div>
      <loading-spinner
        v-if="spinner"
        :is-loading="spinner"
        is-center
        is-small
      />
    </div>
  </modal-dialog>
</template>

<script>
import ModalDialog from "@components/ModalDialog.vue";
import {dlg} from "@utils/dlgUtils";
import LoadingSpinner from "@components/LoadingSpinner";

const WaitForProcessDialog = {
  name: "WaitForProcessDlg",
  components: {
    LoadingSpinner,
    ModalDialog,
  },
  props: {
    message: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    spinner: {
      type: Boolean,
      default: true,
    }
  },
}
export default WaitForProcessDialog

export function showWaitDlg( title, message, spinner ) {
  dlg.open(WaitForProcessDialog, {title, message, spinner}, false)
}
export function closeWaitDlg( ) {
  dlg.closeAll()
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

  .message-area {
    width: 20em;
    height: 6em;
    & .message-text {
      margin-bottom: 1em;
    }
  }
</style>
