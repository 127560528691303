


















































































































import ModalWrapper from '@components/ModalWrapper.vue'
import AstCheckbox from '@components/Checkbox.vue'
import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import { userData } from '@services/userData'
import scheduleData from '@services/scheduleData'
import { setLoading } from '@components/Loading.vue'
import DateTimePicker from '@components/DateTimePicker.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'

const examLengths = []
for (let i = 15; i <= 120; i += 5) {
	examLengths.push(i)
}

interface IDicomTag {
	id: number
	name: string
}
interface IExcludedTag {
	id: string
	deleted: boolean
	dicomTag: number // from IDicomTag.id
	name: string
}

let dicomTags: IDicomTag[] = null
let tagMap = {}

const ScheduleModalityDlg = {
	name: 'ScheduleModalityDlg',
	components: {
		AstCheckbox,
		ModalWrapper,
		DlgHeader,
		DateTimePicker,
	},
	props: {
		modalityToEdit: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			scheduleModality: null,
			selectedTag: null,
			examLengths,
			modalities: [],
			dicomTags: [],
			excludedTags: [],
			isSaving: false,
		}
	},
	computed: {
		canSave() {
			if (!this.scheduleModality) return false
			const modality: IScheduleModality = this.scheduleModality
			return modality.name && modality.modalityId && modality.aeTitle
		},
	},
	async created() {
		this.scheduleModality = JSON.parse(JSON.stringify(this.modalityToEdit))
		if (!dicomTags) dicomTags = await scheduleData.getDicomTags()
		this.dicomTags = dicomTags

		// copy scheduleModality.excludedTags to an array of IDicomTag for v-select
		const modalityExcludedTags: IExcludedTag[] = this.scheduleModality.excludedTags
		const excludedTags: IDicomTag[] = modalityExcludedTags
			.filter(t => !t.deleted)
			.map(t => ({
				id: t.dicomTag,
				name: dicomTags.find(d => d.id === t.dicomTag).name,
			}))
		this.excludedTags = excludedTags

		this.modalities = this.$store.state.static.modalities
	},
	methods: {
		async save() {
			this.isSaving = true
			try {
				this.updateModalityExcludedTags()
				const id = await scheduleData.saveModality(this.scheduleModality)
				if (id) this.scheduleModality.id = id
				dlg.close(this, true, this.scheduleModality)
			} finally {
				this.isSaving = false
			}
		},
		async deleteModality() {
			this.isSaving = true
			try {
				if (!(await showConfirm('Are you sure you want to delete this modality?'))) return
				this.scheduleModality.deleted = true
				await scheduleData.saveModality(this.scheduleModality)
				dlg.close(this, true, this.scheduleModality)
			} finally {
				this.isSaving = false
			}
		},
		close() {
			if (!this.isSaving) dlg.close(this, true)
		},
		updateModalityExcludedTags() {
			let modalityExcludedTags: IExcludedTag[] = this.scheduleModality.excludedTags
			let excludedTags: IDicomTag[] = this.excludedTags
			// set existing modalityExcludedTag to deleted if it doesn't exist in excludedTags
			modalityExcludedTags = modalityExcludedTags.map(x => ({
				...x,
				deleted: !excludedTags.some(t => t.id === x.dicomTag),
			}))
			// add new excluded tags
			const newTags: IExcludedTag[] = excludedTags
				.filter(t => {
					return !modalityExcludedTags.some(x => x.dicomTag === t.id)
				})
				.map(t => ({
					id: null,
					dicomTag: t.id,
					name: t.name,
					deleted: false,
				}))
			modalityExcludedTags.push(...newTags)
			this.scheduleModality.excludedTags = modalityExcludedTags
		},
	},
}

export default ScheduleModalityDlg

export function openScheduleModalityDlg(modalityToEdit: IScheduleModality): Promise<IScheduleModality> {
	if (!modalityToEdit) {
		modalityToEdit = {
			id: null,
			name: null,
			modalityId: null,
			aeTitle: null,
			stationName: null,
			patientNameLabel: null,
			patientIdLabel: null,
			responsiblePersonLabel: null,
			accessionNumberLabel: null,
			displaySequence: null,
			defaultExamLength: 15,
			enforceDicomPn: false,
			excludedTags: [],
			deleted: false,
		}
	}
	return dlg.open(ScheduleModalityDlg, { modalityToEdit })
}
