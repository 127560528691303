<template>
	<modal-wrapper @appear="scrollToSelectedEmail" @close="close">
		<div class="modal-container">
			<dlg-header title="Email Log" @close="close" />
			<div class="modal-body">
				<article
					v-for="(email, i) in emails"
					:key="i"
					:ref="`email-${i}`"
					:class="{ selected: selectedEmailIndex === i }"
				>
					<p class="email-date">
						<strong>
							{{ email.date | localizeDate({ forceUTC: true, showTime: true }) }}
						</strong>
						<svg-icon icon="email" fixed class="is-muted" />
					</p>
					<h5>
						<span class="label">Subject</span>
						{{ email.subject }}
					</h5>
					<p>
						<span class="label">From</span>
						{{ email.from }}
					</p>
					<div>
						<span class="label">To</span>
						<ul>
							<li v-for="(recipient, j) in email.to" :key="j">
								{{ recipient.email }}
								<span class="email-status">
									(
									<span>{{ recipient.status }}</span>
									<span v-if="recipient.status === 'Opened'">
										{{ recipient.date | localizeDate({ forceUTC: true, showTime: true }) }}
									</span>
									)
								</span>
							</li>
						</ul>
					</div>
					<pre v-if="email.message">{{ email.message }}</pre>
				</article>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import AstButton from '@components/Button'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper'
import { dlg } from '@utils/dlgUtils'

const EmailLogDlg = {
	name: 'EmailLogDlg',
	components: {
		AstButton,
		DlgHeader,
		ModalWrapper,
	},
	props: {
		emails: {
			type: Array,
			required: true,
		},
		selectedEmailIndex: {
			type: Number,
			default: 0,
		},
	},
	methods: {
		close() {
			dlg.close(this, true)
		},
		scrollToSelectedEmail() {
			const element = this.$refs[`email-${this.selectedEmailIndex}`]
			if (element.length) element[0].scrollIntoView()
		},
	},
}

export default EmailLogDlg

export function openEmailLogDlg({ emails, selectedEmailIndex }) {
	return dlg.open(EmailLogDlg, { emails, selectedEmailIndex })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.modal-container {
	background: var(--secondary-bg);
}
article {
	border-bottom-color: var(--secondary-border);
}
pre {
	background: var(--table-row-odd-bg);
	white-space: pre-wrap;
	line-height: 1.5;
}
ul {
	list-style-type: none;
}
h4 {
	padding-top: 1em;
}
h5 {
	font-size: 1em;
}
.email-date,
pre,
.email-status {
	font-size: 0.9em;
}
.email-date {
	text-align: right;
}
pre {
	margin-top: 1.5em;
	padding: 1em;
}
.modal-container {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 90vw;
	max-width: 800px;
	height: 90vh;
	max-height: 800px;
	overflow: hidden;
	font-size: 0.9em;
}
.modal-body {
	height: 100%;
	overflow: auto;
	border-top: 1px solid var(--primary-border);
}

article {
	border-bottom: 1px solid;
	padding: 2em 1em;
	&.selected {
		background: var(--table-row-selected-bg);
	}
}

article:last-child {
	border-bottom: 0;
}

.label {
	display: block;
	font-size: 0.8em;
	padding-top: 1em;
	font-weight: normal;
}

.label,
.email-status {
	color: var(--secondary-label);
}
</style>
