import _ from 'lodash'

export function openPopUp(urlToOpen, target = '_blank', width = 0, height = 0) {
  let error = {
    error: false,
    errorMessage: ''
  }
  let parameters = "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, noreferrer"
  if (_.isInteger(width) && width > 0) {
    parameters += ', width=' + width
  }
  if (_.isInteger(height) && height > 0) {
    parameters += ', height=' + height
  }
  const popupWindow = window.open(urlToOpen, target, parameters)
  try {
    popupWindow.focus()
    return error
  } catch (e) {
    error.error = true
    error.errorMessage = 'Pop-up Blocker is enabled! Please add this site to your exception list.'
    return error
  }
}

export function openTab(urlToOpen) {
  let error = {
    error: false,
    errorMessage: ''
  }
  const newTab = window.open(urlToOpen, 'tab')
  try {
    newTab.focus()
    return error
  } catch (e) {
    error.error = true
    error.errorMessage = 'Pop-up Blocker is enabled! Please add this site to your exception list.'
    return error
  }
}

