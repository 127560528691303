<template>
	<ol>
		<li v-for="(thumbnail, i) in thumbnails" :key="i">
			<div
				class="thumbnail"
				alt="Thumbnail preview of first image in series"
				:style="{
					'background-image': `url(
						 ${findThumbnailUrl(thumbnail)}
						)`,
				}"
				draggable
				@dragstart="onDragStart(thumbnail, $event)"
				@click.prevent="$emit('thumbnail-click', thumbnail)"
			></div>
			<svg-icon v-if="thumbnail.isMultiFrame" icon="play-box-outline" />
			<div class="thumbnail-label">
				<strong v-if="thumbnail.seriesNumber">{{ thumbnail.seriesNumber }}</strong>
				<span v-if="thumbnail.simpleName" class="thumbnail-name">{{ thumbnail.simpleName }}</span>
				<p v-if="thumbnail.numberOfImages > 1" class="thumbnail-images"> ({{ thumbnail.numberOfImages }}) </p>
			</div>
		</li>
	</ol>
</template>

<script>
import { findThumbnailUrl } from '@utils/urlUtility'
export default {
	name: 'StudiesListDetailThumbnails',
	props: {
		studyId: String,
		thumbnails: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		findThumbnailUrl,
		onDragStart(thumbnail, event) {
			const data = JSON.stringify({ seriesId: thumbnail.seriesId, studyId: this.studyId })
			event.dataTransfer.setData('text/plain', data)
		},
	},
}
</script>

<style lang="scss" scoped>
.thumbnail {
	height: 125px;
	background-size: contain;
	background-color: black;
	background-position: center;
	background-repeat: no-repeat;
}

.thumbnail-label,
strong,
.thumbnail-images {
	font-size: 0.9em;
	text-align: center;
	word-break: break-word;
	hyphens: auto;
}

strong + .thumbnail-name:before {
	content: ': ';
}

ol {
	list-style: none;

	li {
		cursor: pointer;
		list-style: none;
		display: inline-block;
		width: 125px;
		margin: 10px;
		vertical-align: top;
		position: relative;

		.icon {
			position: absolute;
			top: 0;
			right: 0;
			color: white;
			font-size: 2em;
		}
	}
}
</style>
