<template>
	<div>
		<ast-modal-wrapper @close="close">
			<div class="dialog merge-studies">
				<dlg-header title="Select studies to merge with this one." @close="close" />
				<div class="dialog-body">
					<!-- Results are loaded, no results -->
					<section v-show="!isLoading && !list.length" class="no-results">
						<em>
							No other studies were found for this patient and modality.
						</em>
					</section>

					<!--Table list-->
					<data-table
						v-show="list.length"
						:columns="columns"
						:rows="list"
						:sort="listSort"
						:current-page="currentPage"
						:sticky-headers="true"
						:results-per-page="resultsPerPage"
						@sort-selected="handleSortSelected"
						@row-open="toggleStudy($event)"
					>
						<template #row="{ row }">
							<td>
								<ast-checkbox
									style="margin-bottom: 0;"
									:value="isChecked(row)"
									:disabled="!!row.mostRecentReportRecipient"
									@click.prevent
								/>
							</td>
							<td style="font-size: 0.85em;">
								{{ row.studyDateTime | localizeDate({ forceUTC: false }) }}
							</td>
							<td style="font-size: 0.85em;">
								{{ row.studyDescription }}
							</td>
							<td style="font-size: 0.85em;">
								{{ row.imageCount }}
							</td>
							<td style="width: auto; white-space: initial;">
								<span v-if="row.mostRecentReportRecipient" class="is-warning">
									Study cannot be merged since it is included in a report.
									<!-- add icon to encourage user to click on row for more info -->
									<svg-icon icon="question-circle" fixed />
								</span>
							</td>
						</template>
					</data-table>
				</div>
				<div class="footer right">
					<ast-checkbox v-model="checkAll" class="check-all" @click.native="toggleAll">
						{{ checkAll ? 'Deselect All' : 'Select all' }}
					</ast-checkbox>
					<button class="btn btn-default" @click="close()">Cancel</button>
					<button class="btn btn-primary" :disabled="!selectedStudyIds.length || isMerging" @click="merge">
						Merge studies
					</button>
				</div>
				<div v-show="isLoading || isMerging" class="loading">
					<svg-icon icon="spinner" pulse fixed />
					<p v-if="isMerging">Please wait while the studies are merging &hellip;</p>
				</div>
			</div>
		</ast-modal-wrapper>
	</div>
</template>

<script>
import { dlg } from '@utils/dlgUtils'
import AstModalWrapper from '@components/ModalWrapper'
import AstStudiesListCards from '@components/view/StudiesListCards'
import AstCheckbox from '@components/Checkbox.vue'
import DlgHeader from '@components/DlgHeader.vue'
import ListMixin from '@mixins/list'
import studyData from '@services/studyData'
import { showReportStudyWarning } from '@/router/views/StudyList.vue'

const MergeStudies = {
	name: 'MergeStudies',
	components: {
		AstCheckbox,
		AstModalWrapper,
		AstStudiesListCards,
		DlgHeader,
	},
	mixins: [ListMixin],
	props: {
		sourceStudy: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isDialog: true,
			selectedStudyIds: [],
			checkAll: false,
			isMerging: false,
			filter: {
				order: {
					by: '',
					isAscending: false,
				},
			},
			columns: [
				{}, // checkbox
				{
					name: 'Study Date',
					sortName: 'StudyDateTime',
					columnName: 'studyDateTime',
					isSortable: true,
				},
				{
					name: 'Description',
					sortName: 'description',
					columnName: 'description',
					isSortable: true,
				},
				{
					name: 'Images',
					sortName: 'ImageCount',
					columnName: 'imageCount',
					isSortable: true,
				},
				{}, // extra space
			],
		}
	},
	mounted() {
		this.filter.term = this.searchTerm
		this.refreshList()
	},
	methods: {
		async fetchList(options) {
			const modality = this.$store.state.static.modalities.find(m => m.name === this.sourceStudy.modality)
			const params = {
				page: 0,
				results: 1000,
				queryTerm: this.sourceStudy.patientId || this.sourceStudy.patientName,
				modalityIds: [modality.id],
				orderby: this.filter.order.by,
				isOrderAscending: this.filter.order.isAscending,
			}
			const data = await this.$api.study.getList(params)
			if (data) {
				this.list = data.results.filter(s => {
					if (s.studyId === this.sourceStudy.studyId) return false
					if (s.patientId !== this.sourceStudy.patientId) return false
					if (s.patientName !== this.sourceStudy.patientName) return false
					if (s.ownerName !== this.sourceStudy.ownerName) return false
					return true
				})
			}
		},
		isChecked(row) {
			return this.selectedStudyIds.includes(row.studyId)
		},
		toggleStudy(row) {
			if (row.mostRecentReportRecipient)
				return showReportStudyWarning('merge', row.mostRecentReportRecipient, row.mostRecentReportSentDate)
			if (this.isChecked(row)) {
				this.selectedStudyIds = this.selectedStudyIds.filter(s => s !== row.studyId)
			} else {
				this.selectedStudyIds.push(row.studyId)
			}
		},
		toggleAll() {
			if (this.checkAll) {
				this.selectedStudyIds = []
			} else {
				this.selectedStudyIds = this.list.map(s => s.studyId)
			}
		},
		async merge() {
			if (this.isMerging || !this.selectedStudyIds.length) return
			this.isMerging = true
			try {
				await studyData.mergeStudies([this.sourceStudy.studyId, ...this.selectedStudyIds])
				const patient = this.sourceStudy.patientName || this.sourceStudy.patientId
				this.$notifications.addSuccess(`The studies ${patient ? `for ${patient}` : ''} were successfully merged!`)
				dlg.close(this, true, true)
			} finally {
				this.isMerging = false
			}
		},
		close() {
			dlg.close(this, true, false)
		},
	},
}

export default MergeStudies
export function openMergeStudies(sourceStudy) {
	return dlg.open(MergeStudies, { sourceStudy })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.merge-studies,
.loading {
	background: var(--secondary-bg);
}
th,
td {
	width: 0.1%;
	white-space: nowrap;
}
.no-results {
	padding: 16px;
}
.merge-studies {
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	padding: 0;
	height: -webkit-fill-available;
}
@media (min-width: $mqSmall) {
	.merge-studies {
		width: 800px;
		height: 70vh;
	}
}
.dialog-body {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background: var(--primary-bg);
	border: 1px solid var(--secondary-border);
	border-left: 0;
	border-right: 0;
	overflow: auto;
	height: 100%;
	padding: 0;
}
.footer {
	padding: 0 16px 16px 16px;
}
.check-all {
	flex-grow: 1;
	margin-bottom: 0;
	font-size: 0.9em;
}
.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0.6;
	.icon {
		font-size: 4em;
	}
	p {
		margin-top: 16px;
		font-size: 1.2em;
	}
}
</style>
