<template>
	<div id="signalpet">
		<ast-toolbar :is-primary="true" style="align-items: center;">
			<toolbar-branding />
			<toolbar-button icon="arrow-left" @click.native="goBack()" />
			<h3 style="margin: 0 0 0 1em">SignalPET</h3>
			<span style="flex-grow:1"></span>
			<user-menu />
		</ast-toolbar>
		<iframe v-if="studyUrl" :src="studyUrl"></iframe>
	</div>
</template>

<script>
import AstToolbar from '@components/Toolbar.vue'
import ToolbarButton from '@components/ToolbarButton.vue'
import ToolbarBranding from '@components/ToolbarBranding.vue'
import UserMenu from '@components/UserMenu.vue'
import { dicomPartners } from '@services/dicomPartners'

export default {
	name: 'SignalPET',
	components: {
		AstToolbar,
		ToolbarBranding,
		ToolbarButton,
		UserMenu,
	},
	data() {
		return {
			studyUrl: null,
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},
	async created() {
		let studyId = this.$route.params.studyId
		let clinic = this.$route.params.clinicCode
		let log = await dicomPartners.getSignalPetLog(studyId, clinic)
		if (log) {
			this.studyUrl = log.studyUrl
		}
	},
	methods: {
		goBack() {
			let route = {
				path: (this.prevRoute && this.prevRoute.path) || '/',
			}
			this.$router.push(route)
		},
	},
}
</script>

<style lang="scss">
#signalpet {
	display: flex;
	flex-direction: column;
	height: 100%;
	iframe {
		flex-grow: 1;
		width: 100%;
		border: none;
	}
}
</style>
