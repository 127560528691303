<template>
	<modal-wrapper @close="close">
		<div id="dicom-send-dlg" class="dialog" style="padding: 0;">
			<dlg-header title="DICOM Send" @close="close()" />
			<div style="padding: 0 20px 20px 20px">
				<div class="form-row">
					<button class="btn btn-default" @click="openSelectImagesModal">Select images</button>
					<span style="margin-left: 5px;">({{ selectedSeries.length }} selected)</span>
				</div>
				<div class="form-row">
					<label>
						Device
						<a @click="configure()">Configure</a>
					</label>
					<select v-model="device" class="select" style="width:100%" @change="setDeviceConfig()">
						<option :value="null">Manual</option>
						<option v-for="device in devices" :key="device.id" :value="device">
							{{ device.description }} ({{ device.aeTitle }})
						</option>
					</select>
				</div>
				<div class="form-row">
					<label>AE Title</label>
					<input
						v-model.trim="deviceConfig.aeTitle"
						class="input"
						maxlength="16"
						type="text"
						pattern="^[^\\]{1,16}$"
						title="Up to 16 characters.  No backslashes."
					/>
				</div>
				<div class="form-row">
					<label>Hostname</label>
					<input v-model="deviceConfig.hostname" class="input" />
				</div>
				<div class="form-row">
					<label>Port</label>
					<input v-model.number="deviceConfig.port" class="input" type="number" />
				</div>
				<div class="form-row">
					<label>User AE Title</label>
					<input
						v-model.trim="deviceConfig.userAeTitle"
						class="input"
						maxlength="16"
						type="text"
						pattern="^[^\\]{1,16}$"
						title="Up to 16 characters.  No backslashes."
					/>
				</div>
				<div v-if="testResult != null" class="form-row test-result">
					Test:
					<span class="status" :class="{ error: testResult.isError }">
						{{ testResult.statusName }}
					</span>
					<a v-if="testResult.logs.length" @click="showTestLog()">
						[View Logs]
					</a>
				</div>
				<div class="footer right">
					<button class="btn btn-default" @click="test()">Test Connection</button>
					<span style="flex-grow:1"></span>
					<button class="btn btn-primary" @click="send()">Send</button>
					<button class="btn btn-default" @click="close()">Cancel</button>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { dicomSend } from '@services/dicomSend'
import { openDicomPeersDlg } from '@dialogs/DicomPeersDlg.vue'
import { openEmailStudyImagesDlg } from '@components/view/EmailStudyFormImagesModal.vue'
import { showAlert, showLog } from './MessageDlg.vue'
import { openDicomSendInfo } from './DicomSendInfo.vue'
import { eventBus } from '@services/eventBus'

const DicomSendDlg = {
	name: 'DicomSendDlg',
	components: {
		DlgHeader,
		ModalWrapper,
	},
	computed: {
		testResult() {
			return this.dicomSend.items.find(i => i.queueItemId === this.testItemId)
		},
		selectedSeries() {
			return this.images.filter(i => !i.excluded).map(i => i.seriesId)
		},
	},
	data() {
		return {
			devices: [],
			device: null,
			deviceConfig: {
				aeTitle: null,
				hostname: null,
				port: 0,
				userAeTitle: null,
			},
			testItemId: null,
			dicomSend,
		}
	},
	props: ['study', 'images'],
	methods: {
		async loadDevices() {
			this.devices = await dicomSend.getDevices()
		},
		setDeviceConfig() {
			if (this.device) {
				Object.assign(this.deviceConfig, this.device)
			}
		},
		showTestLog() {
			showLog(this.testResult.logs)
		},
		async configure() {
			await openDicomPeersDlg()
			this.loadDevices()
		},
		close() {
			dlg.close(this)
		},
		async test() {
			let d = this.deviceConfig
			if (!dicomSend.validateDevice(d)) return
			this.testItemId = await dicomSend.sendEcho(d)
		},
		async send() {
			let series = this.images.filter(i => !i.excluded && i.entireSeries).map(i => i.seriesId)
			let images = this.images.filter(i => !i.excluded && !i.entireSeries).map(i => i.seriesId)

			if (series.length === 0 && images.length === 0) {
				showAlert('No images selected to send')
				return
			}

			let d = this.deviceConfig
			if (!dicomSend.validateDevice(d)) return

			await dicomSend.sendImages(d, this.study.studyId, series, images)
			dlg.close(this)
			eventBus.once('routechanged', openDicomSendInfo)
		},
		openSelectImagesModal() {
			openEmailStudyImagesDlg([this.study], this.images)
		},
	},
	created() {
		this.images.forEach(i => {
			i.excluded = false
		})
		this.loadDevices()
	},
}

export default DicomSendDlg

export function openDicomSendDlg(study, images) {
	return dlg.open(DicomSendDlg, { study, images })
}
</script>

<style lang="scss">
#dicom-send-dlg {
	.test-result {
		.status {
			color: var(--icon-success);
			&.error {
				color: var(--icon-danger);
			}
		}

		a {
			font-size: 0.7em;
		}
	}
}
</style>
