<template>
	<modal-wrapper :close-on-overlay-click="false" @close="close">
		<div class="new-folder dialog">
			<dlg-header :title="folder ? 'Edit Folder' : 'New Folder'" @close="close" />
			<div class="dialog-body">
				<label>Name</label>
				<input v-model="newFolder.name" v-focus class="input" style="margin: 4px 0 16px 0;" @keypress.enter="save" />
				<template v-if="hasChildFolder">
					<p style="font-size: 0.9em;">
						<svg-icon icon="info-circle" class="is-info" />
						This folder cannot be moved inside another because it currently has subfolders.
					</p>
				</template>
				<template v-if="!hasChildFolder">
					<label v-if="!folder">Create inside existing folder</label>
					<label v-else>Move into existing folder</label>
					<select v-model="newFolder.parentFolderId" class="select" style="width: 100%; margin-top: 4px;">
						<option v-for="folder in parentFolders" :key="folder.id" :value="folder.id">
							{{ folder.name }}
						</option>
					</select>
				</template>
			</div>
			<div class="footer right" style="padding: 16px; margin-top: 0;">
				<button class="btn btn-primary" style="min-width: 72px;" :disabled="!newFolder.name" @click="save">
					{{ folder ? 'Save' : 'Create folder' }}
				</button>
				<button class="btn btn-default" @click="close">Cancel</button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper'
import { dlg } from '@utils/dlgUtils'
import AstCheckbox from '@components/Checkbox.vue'
import { studyFolders } from '@services/folders'
import { showAlert } from '@dialogs/MessageDlg.vue'

const StudyFolder = {
	name: 'StudyFolder',
	components: {
		DlgHeader,
		ModalWrapper,
		AstCheckbox,
	},
	props: {
		folder: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			folders: studyFolders.folders,
			newFolder: {
				id: null,
				name: '',
				sequence: 0,
				parentFolderId: null,
			},
		}
	},
	created() {
		if (this.folder) {
			this.newFolder = { ...this.folder }
		}
	},
	computed: {
		parentFolders() {
			const parentFolders = this.folders.filter(f => {
				if (f.parentFolderId) return false
				if (this.folder && f.id === this.folder.id) return false
				return true
			})
			// add null option
			parentFolders.unshift({
				id: null,
				name: ' ',
			})
			return parentFolders
		},
		hasChildFolder() {
			if (!this.folder) return false
			return this.folders.some(f => f.parentFolderId === this.folder.id)
		},
	},
	methods: {
		async save() {
			if (!this.newFolder.name) return
			if (!this.newFolder.id && this.folders.some(f => f.name === this.newFolder.name)) {
				showAlert('That folder name is already in use.')
				return
			}
			if (this.folder && this.newFolder.parentFolderId !== this.folder.parentFolderId) {
				// if changing a folder's parent folder, reset sequence to 0 (top)
				this.newFolder.sequence = 0
			}
			const data = await studyFolders.save([this.newFolder])
			const newFolder = data[0]
			dlg.close(this, true, newFolder)
		},
		close() {
			dlg.close(this, true)
		},
	},
}

export default StudyFolder

export function openStudyFolder(folder) {
	return dlg.open(StudyFolder, { folder })
}
</script>

<style lang="scss">
.new-folder.dialog {
	overflow: visible;
	padding: 0;
	width: 300px;
	.dialog-body {
		padding: 16px;
		background: var(--primary-bg);
		border: 1px solid var(--secondary-border);
	}
}
</style>
