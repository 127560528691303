<template>
	<div>
		<h4>Email Log</h4>
		<ul class="short-log">
			<li v-for="(email, i) in emails" :key="i">
				<a @click="selectEmail(i)">
					{{ email.date | localizeDate({ forceUTC: true, showTime: false }) }}
					-
					{{ email.to.map(to => to.email).join(', ') }}
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import { openEmailLogDlg } from '@dialogs/EmailLogDlg.vue'
export default {
	name: 'StudiesListEmailLog',
	props: {
		emails: {
			type: Array,
			required: true,
		},
	},
	methods: {
		selectEmail(index) {
			openEmailLogDlg({ emails: this.emails, selectedEmailIndex: index })
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

ul.short-log {
	list-style-type: none;
	padding: 0.5em 0;
}
h4 {
	padding-top: 1em;
}
a {
	cursor: pointer;
	border-bottom: 1px solid;
	margin-right: 1em;
}
</style>
