<template>
	<div id="switching-clinics">
		<h3>Switching clinics &hellip;</h3>
		<p>This will only take a moment.</p>
	</div>
</template>

<script>
export default {
	name: 'SwitchingClinics',
}
</script>

<style lang="scss">
#switching-clinics {
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;
}
</style>
