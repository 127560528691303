
















import { openAddToFolder } from '@dialogs/AddToFolder.vue'
import { studyFolders } from '@services/folders'

export default {
	name: 'StudyFolders',
	props: {
		folderIds: {
			type: Array,
			default: () => [],
		},
		studyId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			studyFolders,
		}
	},
	computed: {
		folders() {
			return this.studyFolders.folders.filter(f => this.folderIds.includes(f.id))
		},
	},
	methods: {
		removeFolder(folder) {
			try {
				const folderIds = this.folderIds.filter(f => f !== folder.id)
				this.studyFolders.updateFolderIdsForStudy(this.studyId, folderIds)
				this.$emit('update', folderIds)
			} catch (err) {
				this.$emit('update', [...this.folderIds, folder.id]) // restore folder if remove request fails
			}
		},
		async addToFolder() {
			const folderIds = await openAddToFolder(this.studyId, this.folderIds)
			if (folderIds) this.$emit('update', folderIds)
		},
	},
}
