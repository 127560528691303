






































































































































































































































































































import Datepicker from '@components/DatePicker.vue'
import { isSameDate, getDateRanges } from '@utils/dateUtils.js'
import { eventBus } from '@services/eventBus'
import { localizeDate } from '@mixins/localization'
import { openSettings } from '@dialogs/Settings.vue'
import { openScheduleModalityDlg } from '@/schedule/dialogs/ScheduleModalityDlg.vue'
import { openScheduleItemDlg } from '@/schedule/dialogs/ScheduleItemDlg.vue'
import { openScheduleModalities } from '@/schedule/dialogs/ScheduleModalities.vue'
import scheduleData, { ScheduleStatus, scheduleStatusIdDesc } from '@services/scheduleData'
import { userData } from '@services/userData'
import { getLocale } from '@/utils/locale'
import { CountryCurrency } from '@/utils/currency'
import ItemStatusMixin from '@/schedule/mixins/ItemStatus'

const roundDownToFive = n => Math.floor(n / 5) * 5
const roundUpToFive = n => Math.ceil(n / 5) * 5
const toTwoDigits = s => (s.toString().length === 1 ? '0' + s : s.toString())
const getHours = date => toTwoDigits(date.getHours())
const getMinutes = date => toTwoDigits(roundDownToFive(date.getMinutes()))
const get24HourTime = date => getHours(date) + getMinutes(date)
let updateTimeInterval
let refreshInterval

interface ITime {
	hours: number
	minutes: number
	localizedDate: string // text to show in time column
	time: string // 24-hour time used for unique key/ref (e.g. "1600" for 4:00PM)
}

const timeSlots = (24 * 60) / 5 // number of five-minute intervals in a day
const times: ITime[] = []
for (let i = 0; i < timeSlots; i++) {
	const date = new Date()
	date.setHours(0, 0, 0, 0)
	date.setTime(date.getTime() + i * (5 * 60000))
	const hours = date.getHours()
	const minutes = date.getMinutes()
	times.push({
		hours,
		minutes,
		localizedDate: localizeDate(date, { showDate: false }),
		time: get24HourTime(date),
	})
}

export default {
	name: 'Schedule',
	components: {
		Datepicker,
	},
	mixins: [ItemStatusMixin],
	data() {
		return {
			selectedDate: new Date(),
			scheduleModalities: [],
			isMobileScheduleActive: true,
			isUnscheduledOnBottom: false,
			showLegend: false,
			isFirstLoad: true,
			isSaving: false,
			dragItemId: null,
			draggingItemCache: [],
			isDropTargetInvalid: false,
			newItemTime: '',
			today: new Date(), // cache today to detect when date advances
			times: Object.freeze(times),
			items: [],
		}
	},
	computed: {
		canCreateScheduleItem: () => userData.canCreateScheduleItem,
		isAdmin: () => userData.permissions.schedulingAdmin,
		unscheduled() {
			return this.items
				.filter(i => i.scheduleDateTime === null)
				.sort((a, b) => {
					if (a.requestedDateTime < b.requestedDateTime) return -1
					if (a.requestedDateTime > b.requestedDateTime) return 1
					return 0
				})
		},
		isTodaySelected() {
			return isSameDate(this.today, this.selectedDate)
		},
		scheduled() {
			if (!this.scheduleModalities.length) return []
			return this.items
				.filter(i => i.scheduleDateTime)
				.map(item => {
					const startTime = this.$options.filters.localizeDate(item.scheduleDateTime, {
						showDate: false,
					})
					const modality = this.scheduleModalities.find(m => m.id === item.scheduleModalityId)
					if (!modality) return null // item's schedule modality no longer exists
					const endDate = new Date(new Date(item.scheduleDateTime).getTime() + modality.defaultExamLength * 60000)
					const endTime = this.$options.filters.localizeDate(endDate, { showDate: false })
					const timeRange = `${startTime}–${endTime}`
					const title = `${item.patientName} ${timeRange}\n(${scheduleStatusIdDesc(item.scheduleStatusId)})`
					return {
						timeRange,
						title,
						...item,
					}
				})
				.filter(Boolean) // remove invalid items
		},
		currencySymbol() {
			const locale = getLocale()
			const country = locale.slice(-2).toUpperCase()
			const currency = CountryCurrency[country] || 'USD'
			try {
				const symbol = (0)
					.toLocaleString(locale, {
						style: 'currency',
						currency,
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					})
					.replace(/\d/g, '')
					.trim()
				return symbol || '$'
			} catch (err) {
				return '$'
			}
		},
	},
	watch: {
		isUnscheduledOnBottom() {
			this.placeItemsAndTimeIndicator()
		},
		isMobileScheduleActive(isActive) {
			if (isActive) {
				this.placeItemsAndTimeIndicator()
				this.$nextTick(this.initialScroll)
			}
		},
		selectedDate() {
			this.updateTimeIndicator()
		},
		'$route.query.date': {
			handler(dateParam) {
				if (!dateParam) return
				let selectedDate
				const dateRanges = getDateRanges()
				if (dateParam === 'today') selectedDate = new Date()
				else if (dateParam === 'yesterday') selectedDate = new Date(dateRanges.Yesterday.startDate)
				else if (dateParam === 'tomorrow') selectedDate = new Date(dateRanges.Tomorrow.startDate)
				else selectedDate = new Date(dateParam)
				this.selectedDate = selectedDate
				this.refreshList()
			},
			immediate: true,
		},
	},
	beforeDestroy() {
		clearInterval(refreshInterval)
		clearInterval(updateTimeInterval)
		eventBus.off('resize', this.placeItemsAndTimeIndicator)
	},
	mounted() {
		if (!scheduleData.config) scheduleData.getConfiguration()
		scheduleData.getModalities().then(r => (this.scheduleModalities = r))
		refreshInterval = setInterval(this.refreshList, 30000)
		updateTimeInterval = setInterval(this.updateTime, 10000)
		eventBus.on('resize', this.placeItemsAndTimeIndicator)
	},
	methods: {
		placeItemsAndTimeIndicator() {
			this.$nextTick(() => {
				this.placeItems()
				this.updateTimeIndicator()
			})
		},
		setSelectedDate(selectedDate: Date = new Date()) {
			if (selectedDate === null) return
			let dateParam: string
			const dateRanges = getDateRanges()
			if (isSameDate(selectedDate, new Date())) dateParam = 'today'
			else if (isSameDate(selectedDate, dateRanges.Yesterday.startDate)) dateParam = 'yesterday'
			else if (isSameDate(selectedDate, dateRanges.Tomorrow.startDate)) dateParam = 'tomorrow'
			else dateParam = selectedDate.toISOString()
			this.$router.replace({
				query: {
					...this.$route.query,
					date: dateParam,
				},
			})
		},
		async refreshList() {
			if (this.dragItemId || this.isSaving) return
			const fromDate = new Date(this.selectedDate)
			const toDate = new Date(this.selectedDate)
			fromDate.setHours(0, 0, 0, 0)
			toDate.setHours(23, 59, 59, 999)
			const promises = [
				scheduleData.getItems(null, fromDate, toDate, false),
				scheduleData.getItems(null, null, null, true),
			]
			const [scheduled, unscheduled] = await Promise.all(promises)
			this.items = [...scheduled, ...unscheduled]
			setTimeout(() => this.placeItems(), this.isFirstLoad ? 500 : 0)
		},
		openScheduleAdmin() {
			openSettings('schedule-administration')
		},
		async configureModalities() {
			this.scheduleModalities = await openScheduleModalities()
			this.$nextTick(this.placeItems)
		},
		async openModality(m: IScheduleModality) {
			if (!this.isAdmin) return
			const newModality: IScheduleModality = await openScheduleModalityDlg(m)
			if (!newModality) return
			if (!m) {
				this.scheduleModalities.push(newModality)
				return
			}
			const modalityIndex = this.scheduleModalities.indexOf(m)
			if (newModality.deleted) {
				this.scheduleModalities.splice(modalityIndex, 1)
			} else {
				this.scheduleModalities.splice(modalityIndex, 1, newModality)
			}
			this.$nextTick(this.refreshList)
		},
		getModalityName(modalityId: string) {
			const modalities = this.$store.state.static.modalities
			const modality = modalities.find(m => m.id === modalityId)
			if (modality) return modality.name
		},
		isSameModality(modalityIdA, modalityIdB) {
			const cr = this.$store.state.static.modalities.find(m => m.name === 'CR').id
			const dx = this.$store.state.static.modalities.find(m => m.name === 'DX').id
			// treat CR and DX as same modality
			if ([cr, dx].includes(modalityIdA) && [cr, dx].includes(modalityIdB)) {
				return true
			}
			return modalityIdA === modalityIdB
		},
		updateTime() {
			const now = new Date()
			if (!isSameDate(now, this.today)) {
				// if Today is selected, advance calendar automatically when day changes
				if (isSameDate(this.today, this.selectedDate)) this.selectedDate = now
				this.today = now
			}
			this.updateTimeIndicator()
		},
		updateTimeIndicator() {
			// update current time indicator
			const nearestRowTime = new Date()
			nearestRowTime.setMinutes(roundDownToFive(nearestRowTime.getMinutes()), 0, 0)
			const currentTimeRow = this.$refs[get24HourTime(nearestRowTime)][0]
			const indicator: HTMLElement = this.$refs.currentTime
			if (!indicator) return
			// position indicator on top of table row for current time
			indicator.style.top = currentTimeRow.offsetTop + 'px'
			indicator.style.left = currentTimeRow.offsetLeft + 'px'
			indicator.style.height = currentTimeRow.offsetHeight + 'px'
			indicator.style.width = currentTimeRow.offsetWidth + 'px'
		},
		placeItems() {
			for (let i = 0; i < this.scheduled.length; i++) {
				const item: IScheduleItem = this.scheduled[i]
				if (this.dragItemId && this.dragItemId !== item.id) continue
				const modality: IScheduleModality = this.scheduleModalities.find(m => m.id === item.scheduleModalityId)
				const time = get24HourTime(new Date(item.scheduleDateTime))
				const rowspan = roundUpToFive(modality.defaultExamLength) / 5
				const startCell = this.$refs[`${item.scheduleModalityId}-${time}`][0]
				if (!startCell) break
				const rect = startCell.getBoundingClientRect()
				const itemEl = this.$refs[item.id][0]
				itemEl.style.top = startCell.offsetTop + 'px'
				itemEl.style.left = startCell.offsetLeft + 'px'
				itemEl.style.width = rect.width + 'px'
				itemEl.style.height = rect.height * rowspan + 'px'
				if (this.dragItemId === item.id) break
			}
			if (this.isFirstLoad) {
				this.updateTime()
				this.initialScroll()
				this.isFirstLoad = false
			}
		},
		placeNewItemPlaceholder(modality?: IScheduleModality, time?: ITime) {
			const el = this.$refs.newItem
			if (this.dragItemId || !modality || !time) {
				el.style.display = 'none'
				return
			}
			const newStartTime = time.hours * 60 + time.minutes
			const newEndTime = newStartTime + modality.defaultExamLength
			const overlapsAnotherItem = this.scheduled.some(i => {
				if (i.scheduleModalityId !== modality.id) return false
				const itemScheduleDate = new Date(i.scheduleDateTime)
				const itemStartTime = itemScheduleDate.getHours() * 60 + itemScheduleDate.getMinutes()
				const itemEndTime = itemStartTime + modality.defaultExamLength
				return (
					(itemStartTime > newStartTime && itemStartTime < newEndTime) ||
					(newStartTime > itemStartTime && newStartTime < itemEndTime)
				)
			})
			if (overlapsAnotherItem) return
			this.newItemTime = time.localizedDate
			const rowspan = roundUpToFive(modality.defaultExamLength) / 5
			const startCell = this.$refs[`${modality.id}-${time.time}`][0]
			if (!startCell) return
			const rect = startCell.getBoundingClientRect()
			el.style.top = startCell.offsetTop + 'px'
			el.style.left = startCell.offsetLeft + 'px'
			el.style.width = rect.width + 'px'
			el.style.height = rect.height * rowspan + 'px'
			el.style.display = 'flex'
		},
		initialScroll() {
			if (!this.scheduleModalities.length) return
			let scrollTo
			if (this.scheduled.length) {
				// scroll to the midpoint between the top of the earliest item and the bottom of the latest
				const scheduledEls: HTMLElement[] = Array.from(this.$el.querySelectorAll('.item.scheduled'))
				const top = Math.min(...scheduledEls.map(e => e.offsetTop))
				const bottom = Math.max(...scheduledEls.map(e => e.offsetTop + e.offsetHeight))
				scrollTo = (top + bottom) / 2 - this.$refs.scheduleScroll.offsetHeight / 2
			} else {
				scrollTo = this.$refs.currentTime.offsetTop - this.$refs.scheduleScroll.offsetHeight / 2
			}
			this.$refs.scheduleScroll.scrollTop = scrollTo
		},
		async openItem(item: IScheduleItem) {
			await openScheduleItemDlg(item)
			this.refreshList()
		},
		async addNewItem(modality?: IScheduleModality, time?: ITime) {
			let values: any = {
				requestedDateTime: this.selectedDate,
			}
			if (modality) {
				values.modalityId = modality.modalityId
				values.scheduleModalityId = modality.id
			}
			if (time) {
				const scheduleDateTime: Date = this.selectedDate
				scheduleDateTime.setHours(time.hours, time.minutes, 0, 0)
				values.scheduleDateTime = scheduleDateTime
			}
			await openScheduleItemDlg(null, null, null, values)
			this.refreshList()
		},
		canDragItem(item: IScheduleItem) {
			if (!this.isAdmin) return false
			return [ScheduleStatus.Scheduled, ScheduleStatus.Queued].includes(item.scheduleStatusId)
		},
		onDragStart(e: DragEvent, item: IScheduleItem) {
			e.dataTransfer.effectAllowed = 'move'
			e.dataTransfer.setData('text', '')
			e.dataTransfer.setDragImage(this.$refs.dragImage, 16, 16)
			this.dragItemId = item.id
			this.draggingItemCache = Object.freeze(JSON.parse(JSON.stringify(this.items)))
		},
		onDragEnterItem(e, item: IScheduleItem) {
			if (!e.target || !e.target.style || !this.dragItemId) return
			if (item.id === this.dragItemId) e.target.style.pointerEvents = 'none'
		},
		async onDragEnd(e) {
			if (!this.dragItemId) return
			this.isSaving = true
			try {
				const item: IScheduleItem = this.items.find(i => i.id === this.dragItemId)
				if (e.dataTransfer.dropEffect === 'move') {
					// if valid drop target, save change
					await scheduleData.scheduleItem(item)
				} else {
					// if invalid drop target, reset change
					this.items = JSON.parse(JSON.stringify(this.draggingItemCache))
					this.$nextTick(this.placeItems)
				}
			} finally {
				this.isSaving = false
				this.dragItemId = null
				this.draggingItemCache = []
				const itemEls = this.$el.querySelectorAll('.item')
				itemEls.forEach(i => (i.style.pointerEvents = 'auto'))
			}
		},
		onDragEnterTime(e, modality?: IScheduleModality, time?: ITime) {
			if (!this.dragItemId) return
			const item: IScheduleItem = this.items.find(i => i.id === this.dragItemId)
			if (!modality || !this.isSameModality(modality.modalityId, item.modalityId)) {
				e.dataTransfer.dropEffect = 'none'
				// while over an invalid drop target, show item in original position
				const item: IScheduleItem = this.items.find(i => i.id === this.dragItemId)
				const originalItem: IScheduleItem = this.draggingItemCache.find(i => i.id === this.dragItemId)
				item.scheduleStatusId = originalItem.scheduleStatusId
				item.scheduleDateTime = originalItem.scheduleDateTime
				item.scheduleModalityId = originalItem.scheduleModalityId
				this.$nextTick(this.placeItems)
				return
			}
			const newStartTime = time.hours * 60 + time.minutes
			const newEndTime = newStartTime + modality.defaultExamLength
			const overlapsAnotherItem = this.draggingItemCache.some(i => {
				if (i.scheduleModalityId !== modality.id) return false
				if (i.id === this.dragItemId) return false
				const itemScheduleDate = new Date(i.scheduleDateTime)
				const itemStartTime = itemScheduleDate.getHours() * 60 + itemScheduleDate.getMinutes()
				const itemEndTime = itemStartTime + modality.defaultExamLength
				return (
					(itemStartTime > newStartTime && itemStartTime < newEndTime) ||
					(newStartTime > itemStartTime && newStartTime < itemEndTime)
				)
			})
			if (overlapsAnotherItem) return
			item.scheduleStatusId = ScheduleStatus.Scheduled
			const scheduleDateTime: Date = new Date(this.selectedDate)
			scheduleDateTime.setHours(time.hours, time.minutes, 0, 0)
			item.scheduleDateTime = scheduleDateTime
			item.scheduleModalityId = modality.id
			this.$nextTick(this.placeItems)
		},
		onDragOverTime(e, modality: IScheduleModality) {
			if (!this.dragItemId) return
			const item: IScheduleItem = this.items.find(i => i.id === this.dragItemId)
			if (!this.isSameModality(modality.modalityId, item.modalityId)) {
				e.dataTransfer.dropEffect = 'none'
			}
		},
		onDragEnterUnscheduled() {
			if (!this.dragItemId) return
			const item: IScheduleItem = this.items.find(i => i.id === this.dragItemId)
			item.scheduleStatusId = ScheduleStatus.Queued
			item.scheduleDateTime = null
			item.scheduleModalityId = null
		},
	},
}
