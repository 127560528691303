




















































import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { FileUtils } from '@utils/fileUtils'
import { uploadData } from '@services/uploads'
import { showAlert } from './MessageDlg.vue'
import { fileSize } from '@/filters'
import { confirmAttachmentFileSize } from './AttachmentFileSize.vue'
import { addNotification } from '@services/notificationService'

const CONTEXT = 'attachFile'

const AttachFileDlg = {
	name: 'AttachFileDlg',
	components: {
		DlgHeader,
		ModalWrapper,
	},
	props: {
		study: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			pollInterval: 0,
			isQueuing: false,
			isUploading: false,
			fileDesc: '',
			uploadFile: null,
			upload: null,
			options: [
				'Endoscopic Video',
				'Eye Certificate',
				'Medical Register',
				'Sale Report',
				'Scope Report',
				'Surgery Report',
			],
		}
	},
	destroyed() {
		this.stopLoading()
	},
	mounted() {
		let dropTarget = this.$el.querySelector('.upload-form')
		dropTarget.addEventListener('dragover', this.allowDrop)
		dropTarget.addEventListener('dragleave', this.dragLeave)
		dropTarget.addEventListener('drop', this.dropFiles)

		this.$refs.fileInput.onchange = this.handleFiles
	},
	methods: {
		findFileIconName: FileUtils.findFileIconName,
		handleFiles(event) {
			this.trySetFile(event.srcElement.files[0])
		},
		dragLeave(event) {
			event.currentTarget.classList.remove('active')
		},
		allowDrop(event) {
			event.preventDefault()
			event.currentTarget.classList.add('active')
		},
		dropFiles(e) {
			e.currentTarget.classList.remove('active')
			e.preventDefault()
			e.stopPropagation()
			if (e.dataTransfer.files.length) {
				this.trySetFile(e.dataTransfer.files[0])
			}
		},
		trySetFile(file) {
			let name = file.name.toLowerCase()
			if (name.includes('.zip')) {
				addNotification('Zip files are not supported. Please unzip and upload the individual files.', 'error')
				return
			}
			this.uploadFile = file
		},
		reset() {
			this.uploadFile = null
			this.$refs.fileInput.value = ''
		},
		async attachFile() {
			this.stopLoading()
			if (!(await confirmAttachmentFileSize(this.study.attachmentTotalSize, [this.uploadFile]))) return
			this.isQueuing = true
			await uploadData.queueFilesForUpload({
				context: CONTEXT,
				files: [this.uploadFile],
				studyInstanceUid: this.study.studyUid,
				description: this.fileDesc,
				allowAttachments: true,
			})
			this.isQueuing = false
			this.upload = uploadData.uploads.find(upload => upload.context === CONTEXT)
			if (this.upload) {
				this.isUploading = true
				await uploadData.startUpload(CONTEXT)
				this.getUploadStatus()
				this.pollInterval = setInterval(this.getUploadStatus, 2000)
			} else {
				this.$notifications.addError('This file type is not allowed')
			}
		},
		async getUploadStatus() {
			if (this.isUploading) {
				let status = await uploadData.getUploadStatus(this.upload.batchId)
				if (status.errorCount > 0) {
					uploadData.UPDATE_UPLOAD({ ...this.upload, isFailedUpload: true })
					status.errorMessages.forEach(e => this.$notifications.addError(e.errorMessage))
				}

				if (status.completeCount > 0) this.close(true)
				else if (this.upload.isFailedUpload) this.stopLoading()
			}
		},
		stopLoading() {
			this.isUploading = false
			clearInterval(this.pollInterval)
			uploadData.stopUploads({ context: CONTEXT })
		},
		close(success) {
			dlg.close(this, true, success)
		},
	},
}

export default AttachFileDlg

export function openAttachFileDlg(study) {
	if (study.attachmentTotalSize > uploadData.MAX_ATTACHMENT_SPACE) {
		// in case the study is somehow already over the attachment limit
		const max = fileSize(uploadData.MAX_ATTACHMENT_SPACE)
		const total = fileSize(study.attachmentTotalSize)
		showAlert(`The attachments on this study have a total filesize of ${total}, which already exceeds the ${max}
			limit for a single study.  No more files may be attached.`)
		return false
	}

	return dlg.open(AttachFileDlg, { study })
}
