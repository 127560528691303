<template>
  <div class="page-wrapper">
    <div class="list-scroll">
      <search-and-filter
        :filter-presets="filterPresets"
        :filter="filter"
        :show-reset-button="true"
        :show-modality-near-search="true"
        @input="updateFilter($event)"
        @reset="updateFilter(defaultFilter)"
        @reset-current-page="currentPage = 0"
      >
        <template #tags>
          <router-link to="/reports/deep-search" tag="button" class="btn btn-default">
            <svg-icon icon="text-search" fixed />
            Keyword search
          </router-link>

          <filter-tag
            v-if="filter.folderId"
            :value="filter.folderId"
            :clear-value="null"
            @input="updateFilterComponent('folderId', $event)"
          >
            <svg-icon icon="folder-outline" fixed />
            {{ getFolderNameById(filter.folderId) }}
          </filter-tag>
          <filter-tag
            v-if="filter.startDate || filter.endDate"
            :value="{ startDate: filter.startDate, endDate: filter.endDate }"
            clear-value=""
            @input="clearFilterDateRange()"
          />
        </template>
        <!-- Study Date -->
        <date-range-picker
          label="Study Date"
          :date-ranges="dateRanges"
          :filter="filter"
          @input="updateFilter($event)"
        />
        <div v-if="folderOptions.length">
          <label>Folder</label>
          <v-select
            :value="filter.folderId"
            :options="folderOptions"
            label="name"
            :searchable="false"
            :reduce="o => o.id"
            :clear-search-on-select="false"
            style="min-width: 140px;"
            :clearable="false"
            @input="updateFilterComponent('folderId', $event)"
          />
        </div>
      </search-and-filter>

      <div v-scroll-nav="!mq.small" class="table-wrapper" :class="{ 'has-cards': !mq.medium }">
        <!-- Results are loaded, no results -->
        <section v-show="!isLoading && !list.length" class="no-results">
          <em>No results found</em>
        </section>

        <!--Table list-->
        <custom-data-table
          v-if="mq.medium"
          v-show="list.length || isLoading"
          ref="table"
          :is-expanding="isExpanding"
          :expanded-row="selectedStudy"
          :collapse-on-list-change="false"
          :columns="columns"
          :default-table-headers="defaultColumns"
          :rows="list"
          :sort="listSort"
          :current-page="currentPage"
          :results-per-page="resultsPerPage"
          :is-loading="isLoading"
          :table-id="tableId"
          @sort-selected="handleSortSelected"
          @row-open="({ studyId, modality }) => openStudy({ studyId, modality })"
          @row-selected="selectStudy"
          @column-action="columnAction"
        >
          <template #row="{ row }">
            <td>
              <div
                class="study-drag-handle"
                draggable="true"
                title="Drag study into folder"
                @dragstart="onDragStart(row, $event)"
                @dragend="onDragEnd"
              >
                <svg-icon icon="drag-vertical" inline class="is-muted" />
              </div>
            </td>
            <td>
              <button class="action-button" @click.stop="openStudy({ studyId: row.studyId, modality: row.modality })">
                <svg-icon icon="eye" fixed />
              </button>
            </td>
            <td
              v-for="(column, i) in filteredList"
              :key="i"
              :class="column.classStr"
              :style="column.styleStr"
            >
              <span v-if="isColumnDataTypeText(column)">
                {{ row[column.dataColumnName] }}
              </span>
              <span v-if="isColumnDataTypeDateTime(column)">
                {{ row[column.dataColumnName] | localizeDate({ forceUTC: false }) }}
              </span>
            </td>
            <td>
              <svg-icon v-if="row.mostRecentReportRecipient" icon="email-check" title="Sent for consultations" fixed />
              <svg-icon v-if="row.hasBeenEmailed" icon="email" title="Study has been emailed" fixed />
              <svg-icon v-if="row.hasReport" icon="file-text-o" title="Study has standard reports" fixed />
              <svg-icon v-if="row.hasCustomReport" icon="file-pdf-o" title="Study has custom reports" fixed />
            </td>
          </template>
          <template #expanded>
            <ast-expanded-study
              :study-id="selectedStudy && selectedStudy.studyId"
              :folder-id="filter.folderId"
              @action="handleAction"
              @study-loading="isExpanding = $event"
              @thumbnail-click="handleThumbnailClick"
              @email-success="updateStudy(selectedStudy, { hasBeenEmailed: true })"
              @appear="scrollExpandedRowIntoView"
              @rename-success="updateStudy(selectedStudy, $event)"
              @merge="refreshList"
              @removed-from-folder="updateStudy(selectedStudy, { wasRemoved: $event })"
              @remove="removeStudyFromList"
            />
          </template>
        </custom-data-table>

        <!--Card list-->
        <ast-studies-list-cards
          v-if="!mq.medium"
          v-show="list.length"
          :studies="list"
          @open-study="({ studyId, modality }) => openStudy({ studyId, modality })"
          @select-study="study => (selectedStudy = study)"
        />
      </div>

      <ast-pagination
        v-show="list.length"
        :current-page="currentPage"
        :results-per-page="resultsPerPage"
        :results-length="list.length"
        :has-more-results="hasMoreResults"
        @set-results-per-page="resultsPerPage = $event"
        @set-current-page="currentPage = $event"
      />

      <!-- PANELS -->
      <modal-drawer
        v-if="!mq.medium && selectedStudy"
        :title="selectedStudy.patientName"
        from-right
        @close="selectedStudy = undefined"
      >
        <ast-expanded-study
          v-if="selectedStudy"
          :study-id="selectedStudy && selectedStudy.studyId"
          :show-thumbnails="false"
          style="padding: 1rem 1.25rem; min-width: 280px;"
          @action="handleAction"
          @thumbnail-click="handleThumbnailClick"
          @email-success="refreshList"
          @rename-success="refreshList"
          @merge="refreshList"
        />
      </modal-drawer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CachedFilter from '@mixins/cachedFilter.js'
import MetaColumn from '@store/models/base/TableMetaColumn'

import ModalDrawer from '@components/ModalDrawer'
import AstStudiesListCards from '@components/view/StudiesListCards'
import AstExpandedStudy from '@components/view/StudiesListExpandedStudy'
import { addNotification } from '@services/notificationService'

import { openDeleteImagesDlg } from '@dialogs/DeleteImagesDlg.vue'
import { openMergeStudies } from '@dialogs/MergeStudies.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { getDateRanges, dateDifferenceInMonths, addMonths } from '@utils/dateUtils'
import { eventBus } from '@services/eventBus'
import { studyFolders } from '@services/folders'
import { SIGNALPET_IMAGEVIEW_ID } from '@services/dicomPartners'
import ListMixin from '@mixins/list.js'
import { openNewReportDlg } from '@reporting/dialogs/NewReportDlg.vue'
import wsData from '@services/wsData'
import { clinicAPI } from '@services/clinicAPI'
import { dicomServiceData } from '@services/dicomServiceData'
import { showAlert } from '@/dialogs/MessageDlg.vue'
import { localizeDate } from '@/mixins/localization'
import { studyData } from '@services/studyData'
import copy from 'copy-to-clipboard'
import CustomDataTable from "@components/CustomDataTable"
import api from "@services/api"
import { GoogleAnalytics } from '@services/analyticsService'

const defaultFilter = {
  term: '',
  order: {
    by: '',
    isAscending: false,
  },
  folderId: null,
  modalityIds: [],
  startDate: '',
  endDate: '',
}
const defaultFilterString = JSON.stringify(defaultFilter)

function extendDefaultFilter(options = {}) {
  return {
    ...JSON.parse(defaultFilterString),
    ...options,
  }
}

export const showReportStudyWarning = (deleteOrMerge, reportRecipient, reportSentDate) => {
  const localizedSentDate = localizeDate(reportSentDate)
  const warning =
    `This study is part of a report sent to ${reportRecipient} on ${localizedSentDate}. ` +
    `Studies cannot be ${deleteOrMerge}d when they are included in a report. If you need to ` +
    ` ${deleteOrMerge} the study, then you must delete the report first.`
  showAlert(warning)
}

export default {
  name: 'StudiesList',
  components: {
    CustomDataTable,
    AstStudiesListCards,
    AstExpandedStudy,
    ModalDrawer,
  },
  mixins: [CachedFilter, ListMixin],
  data() {
    return {
      studyFolders,
      emailItems: [],
      foldersLoaded: false,
      filter: extendDefaultFilter(),
      defaultFilter: extendDefaultFilter(),
      isExpanding: false,
      list: [],
      columns:[],
      defaultColumns: [],
      tableId: 'STUDY_LIST',
      dateRanges: ['Year', 'Month', 'Week', 'Yesterday', 'Today'],
    }
  },
  computed: {
    filteredList: function() {
      return this.columns.filter(i => {
        return (i.isVisible && i.dataColumnName) || i.isVisible
      })
    },
    ...mapState({
      activeClinicCode: state => state.auth.claims.activeClinicCode,
      shouldOpenInNewWindow: state => state.windows.openStudiesInNewWindow,
      tableHeaderSettings: state => state.viewer.tableHeadersSettings.studyListHeader,
    }),
    ...mapGetters(['username', 'fullname']),
    allowReportImageDelete() {
      const clinicFeatures = this.$store.getters.activeClinic?.optionalFeatures || []
      return clinicFeatures.includes('ReportImageDelete')
    },
    folderOptions() {
      return [
        {
          id: null,
          name: ' ',
        },
        ...this.studyFolders.folders,
      ]
    },
  },
  watch: {
    'studyFolders.folders'(newFolders, oldFolders) {
      // if initial folder load happens after study list loads, refresh
      if (!oldFolders.length && !this.foldersLoaded) this.refreshFilterPresets()
      else {
        // add any new folders to the list of filter presets
        newFolders.forEach(f => {
          this.filterPresets[f.id] = extendDefaultFilter({ folderId: f.id })
        })
      }
      this.foldersLoaded = true
    },
    tableHeaderSettings: {
      handler() {
        this.columns = this.tableHeaderSettings.length > 0 ? this.tableHeaderSettings : this.defaultColumns
      }
    },
    filter(newFilter, oldFilter) {
      if (typeof newFilter.startDate === 'string' && typeof newFilter.endDate === 'string') return
      if (newFilter.startDate !== oldFilter.startDate) {
        if (newFilter.endDate === '' || newFilter.endDate === undefined || newFilter.endDate === null) {
          newFilter.endDate = newFilter.startDate
        }
        if ((newFilter.startDate < newFilter.endDate) && dateDifferenceInMonths(newFilter.startDate, newFilter.endDate) > 12){
          this.filter.endDate = addMonths(newFilter.startDate, 12)
        }
      }
      if (newFilter.endDate !== oldFilter.endDate) {
        if (newFilter.startDate === '' || newFilter.startDate === undefined || newFilter.startDate === null) {
          newFilter.startDate = newFilter.endDate
        }
        if ((newFilter.startDate < newFilter.endDate) && dateDifferenceInMonths(newFilter.startDate, newFilter.endDate) > 12){
          this.filter.startDate = addMonths(newFilter.endDate, -12)
        }
      }
    },
  },
  created() {
    eventBus.on('refresh-study-list', this.fetchList)
    window.name = 'worklist'
    setTimeout(() => {
      this.setDefaultHeader()
      this.columns = this.tableHeaderSettings.length > 0 ? this.tableHeaderSettings : this.defaultColumns
      if (this.columns.length > 0 && this.columns[0].id === 0) {
        this.columns.shift()
      }
    },10)
  },
  async mounted() {

    if (this.shouldOpenInNewWindow === null && !this.isMobileOS) {
      this.newWindowSettingsTimeout = window.setTimeout(async () => {
        // Show modal asking for setting
        const pref = await showConfirm(
          `
					<p>Keystone Omni can now open studies in a separate window.<br>
					Would you prefer to open studies in a new window, or continue to open in the current window?</p>
					<p>You can change your preference any time in the <strong>Viewer Configuration</strong> settings menu.</p>
					`,
          {
            title: 'New Study Preferences',
            confirmText: 'Open in New Window',
            cancelText: 'Open in Existing Window',
            showCloseHeader: false,
          }
        )
        this.$store.dispatch('saveOpenStudiesInNewWindow', pref)
        delete this.newWindowSettingsTimeout
      }, 2000)
    }
  },
  destroyed() {
    this.clearStudySubscriptions()
    if (this.newWindowSettingsTimeout) {
      clearTimeout(this.newWindowSettingsTimeout)
      delete this.newWindowSettingsTimeout
    }
    eventBus.off('refresh-study-list', this.fetchList)
  },
  methods: {
    ...mapActions(['deleteStudy', 'openStudy']),
    selectStudy(study) {
      this.selectedStudy = study
    },
    async getRelatedStudiesAndReports(studyId) {
      if (!studyId) return false
      const data = await studyData.getRelatedStudiesAndReports(studyId)
      if (!data) return false
      return data.reports
    },
    refreshFilterPresets() {
      this.filterPresets = {
        month: extendDefaultFilter({ ...getDateRanges().Month }),
        today: extendDefaultFilter({ ...getDateRanges().Today }),
        yesterday: extendDefaultFilter({ ...getDateRanges().Yesterday }),
        week: extendDefaultFilter({ ...getDateRanges().Week }),
      }
      this.studyFolders.folders.forEach(f => {
        this.filterPresets[f.id] = extendDefaultFilter({ folderId: f.id })
      })
    },
    onDragStart(row, event) {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('text', '')
      if (event.dataTransfer.setDragImage) {
        // show entire row as drag image
        const dragImage = event.target.closest('tr')
        const rect = dragImage.getBoundingClientRect()
        event.dataTransfer.setDragImage(dragImage, 32, rect.height / 2)
      }
      // ensure that drawer is visible
      this.$store.dispatch('toggleDrawers', true)
      this.studyFolders.dragStudyId = row.studyId
    },
    onDragEnd() {
      this.studyFolders.dragStudyId = null
    },
    scrollExpandedRowIntoView() {
      this.$nextTick(() => {
        this.$refs.table.scrollSelectedRowIntoView(true)
      })
    },
    async fetchList() {
      const params = {
        ...this.filter,
        page: this.currentPage,
        results: this.resultsPerPage,
        queryTerm: this.filter.term || '',
        orderby: this.filter.order.by,
        isOrderAscending: this.filter.order.isAscending === true,
        importMachineName: [clinicAPI.importMachineName, dicomServiceData.localService?.hostName].filter(x => !!x),
      }
      const data = await this.$api.study.getList(params)
      if (data && data.results) {
        this.list = await Promise.all(
          data.results.map(async s => {
            let study = s
            return study
          })
        )
        this.hasMoreResults = data.hasMoreResults
      }
    },
    clearStudySubscriptions() {
      wsData.unsubscribeByCallback(this.onStudyChanged)
    },
    async onStudyChanged(studyId) {
      let found = this.list.find(s => s.studyId === studyId)
      if (!found) return
      const data = await this.$api.viewer.getStudy({ ids: studyId }, false)
      if (data && data.studies && data.studies.length) {
        const study = data.studies[0]
        found.imageCount = study.imageCount
      }
    },
    getFolderNameById(folderId) {
      const folder = this.studyFolders.folders.find(f => f.id === folderId)
      if (folder) return folder.name
      return 'Folder'
    },
    updateStudyInList({ studyIndex, changes }) {
      const updatedStudy = { ...this.list[studyIndex], ...changes }
      this.list.splice(studyIndex, 1, updatedStudy)
    },
    updateStudy(study, changes) {
      const studyIndex = this.list.indexOf(study)
      this.updateStudyInList({ studyIndex, changes })
      this.selectedStudy = this.list[studyIndex]
    },
    async deleteImages() {
      if (!this.allowReportImageDelete && this.selectedStudy.mostRecentReportRecipient)
        return showReportStudyWarning(
          'delete',
          this.selectedStudy.mostRecentReportRecipient,
          this.selectedStudy.mostRecentReportSentDate
        )
      const didDelete = await openDeleteImagesDlg({ studyId: this.selectedStudy.studyId })
      if (didDelete) this.refreshList()
    },
    async mergeStudies() {
      if (this.selectedStudy.mostRecentReportRecipient)
        return showReportStudyWarning(
          'merge',
          this.selectedStudy.mostRecentReportRecipient,
          this.selectedStudy.mostRecentReportSentDate
        )
      const didMerge = await openMergeStudies(this.selectedStudy)
      if (didMerge) this.$emit('merge')
    },
    removeStudyFromList(studyId) {
      const index = this.list.findIndex(s => s.studyId === studyId)
      this.list.splice(index, 1)
    },
    async handleAction(data) {
      if (!data.action) {
        return
      }

      switch (data.action) {
        case 'view':
          this.openStudy({
            studyId: this.selectedStudy.studyId,
            initialSeriesId: data.seriesId,
            modality: this.selectedStudy.modality,
          })
          break
        case 'new-report':
          openNewReportDlg(this.selectedStudy.studyId)
          break
        case 'request-teleconsultation':
          const { mostRecentReportRecipient, mostRecentReportSentDate } = this.selectedStudy
          if (mostRecentReportRecipient) {
            const localizedSentDate = this.$options.filters.localizeDate(mostRecentReportSentDate)
            const warning =
              `This study was sent to ${mostRecentReportRecipient} ` +
              `on ${localizedSentDate}. Would you like to send this study again?`
            if (!(await showConfirm(warning))) return
          }
          this.$router.push({
            name: 'request-consultants',
            query: { studyId: this.selectedStudy.studyId },
          })
          break
        case 'download-dicom':
          this.$api.file.downloadStudy({
            downloadType: 1,
            clinicCode: this.selectedStudy.clinicCode,
            id: this.selectedStudy.studyId,
          })
          try {
            GoogleAnalytics.sendGAEvent('Study', {
              event_category: 'Download',
              event_label: 'DICOM'
            })
          }
          catch (err) {
            console.log(err)
          }
          break
        case 'download-jpeg':
          this.$api.file.downloadStudy({
            downloadType: 0,
            clinicCode: this.selectedStudy.clinicCode,
            id: this.selectedStudy.studyId,
          })
          try {
            GoogleAnalytics.sendGAEvent('Study', {
              event_category: 'Download',
              event_label: 'JPEG'
            })
          }
          catch (err) {
            console.log(err)
          }
          break
        case 'share-url':
          if (data?.data.length) {
            this.emailItems = data?.data
          }
          const date = new Date()
          try {
            GoogleAnalytics.sendGAEvent('Study', {
              event_category: 'Share',
              event_label: `Username:${this.username} || Name:${this.fullname} || Date:${date} || StudyId:${
              this.selectedStudy.studyId}`
            })
          }
          catch (err) {
            console.log(err)
          }

          const body = {
            recipients: [],
            subject: '',
            images: this.emailItems.filter(i => !i.excluded),
          }
          try {
            const shareUrlResponse = await this.$api.study.getStudyShareUrl(body)
            copy(shareUrlResponse.data, {
              message: 'Select the url and copy it',
            })
            addNotification('Copied to clipboard', 'success')
          } catch (e) {
            addNotification(`Share Study URl error - ${e}`, 'error')
          }
          break
        case 'delete-images':
          this.deleteImages()
          break
        case 'merge-studies':
          this.mergeStudies()
          break
        default:
          this.$notifications.addError('Unsupported action -- Planned for an upcoming release.')
      }
    },
    handleThumbnailClick: function(thumbnail) {
      this.openStudy({
        studyId: this.selectedStudy.studyId,
        modality: this.selectedStudy.modality,
        initialSeriesId: thumbnail.seriesId,
      })
    },
    isColumnDataTypeText: function(column) {
      return column.dataColumnType === 'text'
    },
    isColumnDataTypeDateTime: function(column) {
      return column.dataColumnType === 'dateTime'
    },
    async saveUserPreferences(){
      let payload = {
        tableHeaders: this.columns
      }
      try {
        await api.user.setUserSetting('ViewerSettings', 'StudyListHeader', JSON.stringify(payload))
      } finally {
      }
    },
    revertToDefaultTableHeader(){
      this.columns = this.defaultColumns.map(o => ({...o}))
    },
    abortChanges(previousValue){
      this.columns = previousValue.map(o => ({...o}))
    },
    columnAction(actionObject) {
      switch (actionObject.action){
        case 'RESET':
          this.revertToDefaultTableHeader()
          break
        case 'CANCEL':
          this.abortChanges(actionObject.value)
          break
        case 'SAVE':
          this.saveUserPreferences()
          break
      }
    },
    setDefaultHeader() {
      this.defaultColumns = [
        {id: 1}, // View button
        MetaColumn({
          id:2,
          name: 'Id',
          sortName: 'PatientId',
          dataColumnName: 'patientId',
          classStr: '',
          styleStr: '',
          dataColumnType: 'text',
        }),
        MetaColumn({
          id:3,
          name: 'Patient',
          sortName: 'PatientName',
          dataColumnName: 'patientName',
          classStr: 'break-sm',
          styleStr: '',
          dataColumnType: 'text',
        }),
        MetaColumn({
          id:4,
          name: 'Owner',
          sortName: 'OwnerName',
          dataColumnName: 'ownerName',
          classStr: 'break-md',
          styleStr: '',
          dataColumnType: 'text',
        }),
        MetaColumn({
          id:5,
          name: 'Description',
          sortName: 'StudyDescription',
          dataColumnName: 'studyDescription',
          isVisible: true,
          classStr: 'break-lg',
          styleStr: 'font-size: 0.85em;',
          dataColumnType: 'text',
        }),
        MetaColumn({
          id:6,
          name: 'Study Date',
          sortName: 'StudyDateTime',
          dataColumnName: 'studyDateTime',
          classStr: 'break-md',
          styleStr: 'font-size: 0.85em;',
          dataColumnType: 'dateTime',
        }),
        MetaColumn({
          id:7,
          name: 'Institution',
          sortName: 'InstitutionName',
          dataColumnName: 'institutionName',
          isVisible: true,
          classStr: 'break-lg',
          styleStr: '',
          dataColumnType: 'text',
        }),
        MetaColumn({
          id:8,
          name: 'Modality',
          sortName: 'ModalityId',
          columnName: 'modality',
          dataColumnName: 'modality',
          classStr: '',
          styleStr: 'font-size: 0.85em;',
          dataColumnType: 'text',
        }),
        MetaColumn({
          id:9,
          name: 'Images',
          sortName: 'ImageCount',
          dataColumnName: 'imageCount',
          classStr: '',
          styleStr: 'font-size: 0.85em;',
          dataColumnType: 'text',
        }),
        MetaColumn({
          id:10,
          name: 'Accession #',
          sortName: 'AccessionNumber',
          dataColumnName: 'accessionNumber',
          classStr: '',
          styleStr: '',
          dataColumnType: 'text',
        }),
        {id: 11}, // Icons,
      ]
    }
  },
}
</script>

<style lang="scss">
.study-drag-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: grab;
  user-select: none;

  &>* {
    pointer-events: none;
  }
}

.signalpet-colored {
  width: 17px;
  height: auto;
  margin-right: 5px;
}
</style>
