<template>
	<div class="button-container" :class="{ 'has-caret': hasDropdown }">
		<div style="position:relative">
			<ast-button
				:disabled="disabled"
				:class="[{ active: (isDropdownOpen && hasDropdown) || active }]"
				:type="type"
				v-bind="$attrs"
        @click.prevent.native="toggleDropdown({ isOpen: !isDropdownOpen })"
			>
				<div style="display: inline-flex; flex-grow: 1; align-items: center;">
					<slot name="button"></slot>
				</div>
				<svg-icon
					v-if="!hideCaret"
					icon="caret-down-line"
					width="14"
					height="10"
					style="margin: 0 -4px 0 8px; opacity: 1;"
					:class="['fancy-caret', 'close', { open: isDropdownOpen, up: dropdownLocation === 'top' }]"
				/>
			</ast-button>
		</div>
		<div v-show="isDropdownOpen" ref="dropdown" class="dropdown">
			<slot name="dropdown" :hide="delay => toggleDropdown({ isOpen: false, delayClosing: delay })"></slot>
		</div>
	</div>
</template>

<script>
import { eventBus } from '@services/eventBus'
import { kebabCase } from 'lodash'
import AstButton from '@components/Button'
import { createPopper } from '@popperjs/core'

let popper

export default {
	name: 'ButtonDropdown',
	components: {
		AstButton,
	},
	props: {
		active: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		dropdownLocation: {
			type: String,
			default: 'bottom',
		},
		hideCaret: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'default',
		},
	},
	data() {
		return {
			isDropdownOpen: false,
			debounceTimer: undefined,
		}
	},
	computed: {
		hasDropdown() {
			return !!this.$scopedSlots.dropdown
		},
		hasLabel() {
			return !!(this.label || this.$scopedSlots.label)
		},
	},
	mounted() {
		eventBus.on('resize', this.placeDropdown)
		document.addEventListener('click', this.closeOnOutsideClick)
		const dropdown = this.$refs.dropdown
		if (dropdown) dropdown.style.minWidth = this.$el.clientWidth + 'px'
	},
	beforeDestroy() {
		eventBus.off('resize', this.placeDropdown)
		document.removeEventListener('click', this.closeOnOutsideClick)
	},
	methods: {
		closeOnOutsideClick(e) {
			if (!this.$el.contains(e.target)) this.toggleDropdown({ isOpen: false })
		},
		toggleDropdown({ isOpen, delayClosing }) {
			if (!this.hasDropdown) return
			clearTimeout(this.debounceTimer)
			if (delayClosing && !isOpen) {
				const msDelay = typeof delayClosing === 'number' ? delayClosing : 200
				// Delay closing dropdown to allow multi-click events
				this.debounceTimer = setTimeout(() => {
					this.isDropdownOpen = isOpen
				}, msDelay)
			} else {
				this.isDropdownOpen = isOpen
			}
			this.$nextTick(this.placeDropdown)
		},
		placeDropdown() {
			const dropdown = this.$refs.dropdown
			if (!this.isDropdownOpen || !dropdown) return
			const windowCenter = window.innerWidth / 2
			const buttonCenter = this.$el.offsetLeft + this.$el.clientWidth / 2
			const isNearCenter = Math.abs(windowCenter - buttonCenter) < windowCenter * 0.1
			const isInLeftHalfOfWindow = !isNearCenter && this.$el.getBoundingClientRect().left < windowCenter
			if (popper) popper.destroy()
			popper = createPopper(this.$el, dropdown, {
				modifiers: [
					{
						name: 'offset',
						options: {
							offset: [0, 2],
						},
					},
				],
				placement: isNearCenter ? 'bottom' : isInLeftHalfOfWindow ? 'bottom-start' : 'bottom-end',
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.button-container {
	display: flex;
	flex-direction: column;
	button {
		width: 100%;
	}
}

button {
	position: relative;
}

// Stolen & modified from v-select styling
.btn .fancy-caret {
	transform: scale(1);
	transition: transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
	&.open {
		transform: rotate(180deg) scale(1);
	}
	&.up {
		transform: rotate(180deg) scale(1);
		&.open {
			transform: rotate(360deg) scale(1);
		}
	}
}

.dropdown {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: map-get($zindex, modal);
}
</style>
