<template>
	<div id="releaseNotes">
		<ast-toolbar :is-primary="true" style="align-items: center;">
			<toolbar-branding />
			<h3 style="margin: 0 0 0 1em">Release Notes</h3>
			<span style="flex-grow:1"></span>
		</ast-toolbar>
		<div class="inner">
			<div v-for="release in releases" :key="release.version" class="release">
				<div class="header" @click="show(release)">
					<svg-icon v-if="release.show" icon="minus" fixed />
					<svg-icon v-else icon="plus" fixed />
					<span style="margin-left:16px">{{ release.title }}</span>
				</div>
				<transition name="fade">
					<div v-if="release.show" v-html="release.html"></div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
import AstToolbar from '@components/Toolbar.vue'
import ToolbarBranding from '@components/ToolbarBranding.vue'
import { getReleaseNotes } from '../../dialogs/ReleaseNotes.vue'

export default {
	name: 'ReleaseNotes',
	components: {
		AstToolbar,
		ToolbarBranding,
	},
	data() {
		return {
			releases: [],
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},
	async created() {
		let releases = await getReleaseNotes('ALL')
		releases.forEach(r => {
			r.show = false
		})
		this.releases = releases
	},
	methods: {
		show(release) {
			if (release.show) release.show = false
			else this.releases.forEach(r => (r.show = r === release))
		},
	},
}
</script>

<style lang="scss">
#releaseNotes {
	display: flex;
	flex-direction: column;
	height: 100%;
	.inner {
		padding: 16px 5vw;
		flex-grow: 1;
		width: 100%;
		border: none;
		max-width: 100%;
		overflow: auto;
	}

	.release {
		p,
		li {
			line-height: 1.6;
			margin-top: 0;
			margin-bottom: 1rem;
		}

		.header {
			font-weight: bold;
			cursor: pointer;
		}

		&:not(:last-child) {
			padding-bottom: 1rem;
			margin-bottom: 12px;
			border-bottom: 1px solid var(--primary-border);
		}
	}
}
</style>
