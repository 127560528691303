
































import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { reportService } from '@services/reportService'
import { workflow } from '@services/workflow'
import { TemplateSet } from '../classes'
import router from '@router' // for dialogs
import { eventBus } from '@services/eventBus'
import { userData } from '@services/userData'

const NewReportDlg = {
	name: 'NewReportDlg',
	props: ['studyId'],
	data() {
		return {
			templates: [],
			canSendTeleconsultation: false,
		}
	},
	components: {
		DlgHeader,
		ModalWrapper,
	},
	async created() {
		this.templates = await reportService.getStandardTemplates()
		this.canSendTeleconsultation = userData.permissions.sendTeleconsultation
	},
	methods: {
		startConsultation() {
			router.push({
				name: 'request-consultants',
				query: { studyId: this.studyId },
			})
		},
		async create(set) {
			let report = await reportService.startStandardReport(set.id, this.studyId)

			this.close()
			router.push({
				name: 'standard-report',
				params: { id: report.reportId },
			})
		},
		close() {
			dlg.close(this)
		},
	},
}

export default NewReportDlg

export function openNewReportDlg(studyId: string): Promise<void> {
	return dlg.open(NewReportDlg, { studyId }, false)
}
