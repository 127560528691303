<template>
	<modal-wrapper @close="close">
		<div class="dialog" style="overflow: hidden; max-height: 90vh;">
			<dlg-header title="Anonymize" @close="close" />
			<div
				style="overflow: auto; flex-grow: 1;padding: 16px; background: var(--primary-bg); border: 1px solid var(--primary-border);"
			>
				<p>
					<svg-icon icon="info-circle" class="is-info" fixed />
					Keystone will create an anonymized study using the information below. Some fields may be removed altogether.
				</p>
				<div
					style="display: grid; grid-gap: 8px 32px; padding-top: 8px; grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));"
					@submit.prevent
				>
					<div class="form-group">
						<label class="input-label is-required-right">
							Patient Name
						</label>
						<ast-input v-model.trim="form.patientName" maxlength="100" />
					</div>

					<div class="form-group">
						<label class="input-label is-required-right">
							Patient ID
						</label>
						<ast-input v-model.trim="form.patientId" class="patient-id" maxlength="100" />
					</div>

					<div class="form-group">
						<label class="input-label is-required-right">
							Owner Name
						</label>
						<ast-input v-model.trim="form.ownerName" maxlength="100" />
					</div>

					<ast-input
						v-model.trim="form.accessionNumber"
						label="Accession Number"
						class="form-group accession-number"
						maxlength="50"
					/>
					<ast-input v-model.trim="form.institutionName" label="Institution Name" class="form-group" maxlength="100" />
					<ast-input
						v-model.trim="form.referringPhysiciansName"
						label="Referring Physician"
						class="form-group"
						maxlength="75"
					/>
					<ast-input v-model.trim="form.studyDescription" label="Description" class="form-group" maxlength="254" />
					<div></div>
					<div class="form-group">
						<label class="input-label is-required-right">
							Study Date
						</label>
						<div style="display:flex; align-items: self-start;">
							<div style="flex-grow:1">
								<date-time-picker v-model="form.studyDateTime" />
							</div>
							<span>&nbsp;</span>
							<button class="btn btn-default" @click="form.studyDateTime = new Date()">Today</button>
						</div>
					</div>
					<div class="form-group">
						<label class="input-label">
							Birth Date
						</label>
						<div>
							<date-time-picker v-model="form.patientBirthDate" style="width: 100%; max-width: 100%;" />
							<ast-checkbox v-model="form.removeBirthDate" style="margin-bottom: 0; margin-top: 4px; font-size: 0.9em;"
								>Remove</ast-checkbox
							>
						</div>
					</div>
					<div class="form-group">
						<label class="input-label">Gender</label>
						<div>
							<v-select v-model="form.gender" :clear-search-on-select="false" :options="genders" :searchable="false" />
							<ast-checkbox v-model="form.removeGender" style="margin-bottom: 0; margin-top: 4px; font-size: 0.9em;"
								>Remove</ast-checkbox
							>
						</div>
					</div>
					<div class="form-group">
						<label class="input-label">Weight</label>
						<div style="display: flex;">
							<input v-model.trim="weight" class="input" />
							<select v-model="weightUnit" class="select" style="width: 120px; font-size: 0.8em; margin-left: -1px;">
								<option v-for="unit in weightUnitList" :key="unit" :selected="weightUnit === unit">
									{{ unit }}
								</option>
							</select>
						</div>
						<ast-checkbox v-model="form.removeWeight" style="margin-bottom: 0; margin-top: 4px; font-size: 0.9em;"
							>Remove</ast-checkbox
						>
					</div>
					<div class="form-group">
						<label class="input-label">Species</label>
						<div>
							<v-select
								v-model="form.species"
								:clear-search-on-select="false"
								:options="speciesNames"
								:searchable="false"
							/>
							<ast-checkbox v-model="form.removeSpecies" style="margin-bottom: 0; margin-top: 4px; font-size: 0.9em;"
								>Remove</ast-checkbox
							>
						</div>
					</div>
					<div class="form-group">
						<label class="input-label">Breed</label>
						<div>
							<v-select v-model="form.breed" :clear-search-on-select="false" :options="breedList" :searchable="false" />
							<ast-checkbox v-model="form.removeBreed" style="margin-bottom: 0; margin-top: 4px; font-size: 0.9em;"
								>Remove</ast-checkbox
							>
						</div>
					</div>
					<p v-if="reservedNameInUse" class="is-danger">
						{{ reservedNameInUse }} is reserved for internal use. Please enter a different name.
					</p>
				</div>
			</div>
			<div class="footer right" style="padding: 16px; margin: 0;">
				<ast-button type="primary" :disabled="isSubmitting" @click.native="submit(true)">
					<svg-icon icon="cloud-download" fixed />
					{{ mq.small ? 'Download study' : 'Download' }}
				</ast-button>
				<ast-button type="primary" :disabled="isSubmitting" @click.native="submit()">
					<svg-icon icon="check" fixed />
					{{ mq.small ? 'Save to Keystone' : 'Save' }}
				</ast-button>
				<ast-button @click.native="close">Cancel</ast-button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import AstButton from '@components/Button'
import AstInput from '@components/Input'
import AstCheckbox from '@components/Checkbox'
import DlgHeader from '@components/DlgHeader'
import ModalWrapper from '@components/ModalWrapper.vue'
import api from '@services/api'
import { dlg } from '@utils/dlgUtils'
import { openStudiesDlg } from '@dialogs/StudiesDlg.vue'
import DateTimePicker from '@components/DateTimePicker.vue'
import { showAlert } from './MessageDlg.vue'
import { addNotification } from '@services/notificationService'
import { eventBus } from '@services/eventBus'
import { setLoading } from '@components/Loading.vue'
import studyData from '@services/studyData'
import { formatNumber } from '@utils/numberUtils'

const AnonymizeStudyDlg = {
	name: 'AnonymizeStudyForm',
	components: {
		AstButton,
		AstInput,
		AstCheckbox,
		DateTimePicker,
		DlgHeader,
		ModalWrapper,
	},
	props: {
		study: {
			type: Object,
			required: true,
		},
	},
	watch: {
		'form.species'() {
			this.getBreedList()
		},
	},
	data() {
		return {
			weightUnitList: ['KGS', 'LBS'],
			genders: ['Male', 'Male Castrated', 'Female', 'Female Spayed'],
			speciesNames: [],
			speciesList: [],
			breedList: [],
			isSubmitting: false,
			weightUnit: 'KGS',
			form: {
				studyId: null,
				patientName: '',
				patientId: '',
				gender: null,
				ownerName: '',
				studyDescription: '',
				studyDateTime: new Date(),
				accessionNumber: '',
				institutionName: '',
				referringPhysiciansName: '',
				weight: null,
				breed: null,
				species: null,
				patientBirthDate: new Date(),
				removeBirthDate: false,
				removeGender: false,
				removeSpecies: false,
				removeBreed: false,
				removeWeight: false,
			},
		}
	},
	computed: {
		weight: {
			get() {
				return formatNumber(this.form.weight)
			},
			set(value) {
				this.form.weight = value === '' ? value : this.$options.filters.parseNumber(value)
			},
		},

		reservedNameInUse() {
			const reservedNames = ['DELETE', 'TEST', 'DAILYQA', 'DAILY QA', 'TEST MEASURE', 'QA']
			if (reservedNames.includes(this.form.ownerName)) return this.form.ownerName
			if (reservedNames.includes(this.form.patientName)) return this.form.patientName
			return null
		},
		canSubmitForm() {
			if (this.isSubmitting) return false
			if (this.reservedNameInUse) return false

			const requiredFields = ['ownerName', 'patientId', 'patientName', 'studyDateTime']
			if (requiredFields.some(field => !this.form[field])) {
				return false
			}
			return true
		},
	},
	async created() {
		let info = await studyData.getAnonymizedStudyInfo(this.study.studyId)
		info.studyDateTime = new Date(info.studyDateTime)
		info.patientBirthDate = info.patientBirthDate ? new Date(info.patientBirthDate) : null
		let w = parseFloat(info.weight)
		info.weight = isNaN(w) ? '' : w
		this.form = info
		this.speciesList = await api.patient.getSpecies(null)
		this.speciesNames = this.speciesList.map(s => s.name)
		this.getBreedList()
	},
	methods: {
		async getBreedList() {
			let s = this.speciesList.find(s => s.name === this.form.species)
			if (!s) {
				this.breedList = []
				return
			}
			const list = await this.$api.patient.getBreeds(s.id)
			this.breedList = [...new Set(list.map(breed => breed.name))]
		},
		async close() {
			dlg.close(this, true)
		},
		async submit(download = false) {
			if (!this.canSubmitForm) {
				showAlert('Owner Name, Patient ID, Patient Name, and Study Date are required')
				return
			}
			this.isSubmitting = true
			try {
				const form = { ...this.form }
				if (this.weightUnit === 'LBS') form.weight = form.weight / 2.2046226218488 // convert to KGS
				if (download) {
					studyData.downloadAnonymizedStudy(form)
				} else {
					let p = studyData.createAnonymizedStudy(form)
					setLoading('Creating Study ...', p)
					await p
				}
				dlg.close(this, true, form)
			} finally {
				this.isSubmitting = false
			}
		},
	},
}

export default AnonymizeStudyDlg

export function openAnonymizeStudyDlg(study) {
	return dlg.open(AnonymizeStudyDlg, { study })
}
</script>

<style lang="scss" scoped>
.dialog {
	display: flex;
	position: relative;
	flex-direction: column;
	padding: 0;
	width: 900px;
	max-width: 90vw;
	// height: 80vh;
	max-height: 90vh;
}
.checkbox-label {
	margin-bottom: 0;
}
.vdp-datepicker,
.short-input {
	max-width: 200px;
}

form,
.form-group {
	margin: 8px 0;
}
form {
	margin-bottom: 0;
}

button + button {
	margin-left: 5px;
}

.form-group {
	max-width: 500px;
	&.patient-id,
	&.accession-number {
		max-width: 200px;
	}
}
</style>
