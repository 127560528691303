<template>
	<modal-wrapper @close="close">
		<div class="add-to-folder dialog">
			<dlg-header title="Select Folders" @close="close" />
			<div class="dialog-body">
				<p style="margin-bottom: 16px;">Check all the folders that should include this study.</p>
				<ul class="folders unlist" style="font-size: 0.9em;">
					<template v-for="folder in studyFolders.folders.filter(f => !f.parentFolderId)">
						<li :key="folder.id">
							<ast-checkbox v-model="updatedFolderIds" style="margin: 0;" :array-value="folder.id">
								{{ folder.name }}
							</ast-checkbox>
						</li>
						<li
							v-for="child in studyFolders.folders.filter(f => f.parentFolderId === folder.id)"
							:key="child.id"
							class="child-folder"
						>
							<ast-checkbox v-model="updatedFolderIds" style="margin: 0;" :array-value="child.id">
								{{ child.name }}
							</ast-checkbox>
						</li>
					</template>
					<li style="padding-top: 16px;">
						<a @click="newFolder">New folder &hellip;</a>
					</li>
				</ul>
			</div>
			<div class="footer right" style="padding: 16px; margin-top: 0;">
				<button class="btn btn-primary" style="min-width: 72px;" @click="save">
					OK
				</button>
				<button class="btn btn-default" @click="close">Cancel</button>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper'
import { dlg } from '@utils/dlgUtils'
import AstCheckbox from '@components/Checkbox.vue'
import { openStudyFolder } from '@dialogs/StudyFolder.vue'
import { studyFolders } from '@services/folders'

const CONTEXT = 'attachFile'

const AddToFolder = {
	name: 'AddToFolder',
	components: {
		DlgHeader,
		ModalWrapper,
		AstCheckbox,
	},
	props: {
		studyId: {
			type: String,
			required: true,
		},
		folderIds: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			studyFolders,
			updatedFolderIds: [],
		}
	},
	created() {
		this.updatedFolderIds = [...this.folderIds]
		if (!this.studyFolders.folders.length) {
			// if user has no folders, open New Folder dialog
			// wait a tick to ensure New Folder dialog is on top
			this.$nextTick(() => {
				this.newFolder()
			})
		}
	},
	methods: {
		async newFolder() {
			const newFolder = await openStudyFolder()
			if (newFolder) this.updatedFolderIds.push(newFolder.id)
			// if user cancelled creating their first folder, close this dialog as well
			else if (!this.studyFolders.folders.length) this.close()
		},
		async save() {
			this.isSaving = true
			try {
				await this.studyFolders.updateFolderIdsForStudy(this.studyId, this.updatedFolderIds)
				dlg.close(this, true, this.updatedFolderIds)
			} finally {
				this.isSaving = false
			}
		},
		close() {
			dlg.close(this, true)
		},
	},
}

export default AddToFolder

export function openAddToFolder(studyId, folderIds) {
	return dlg.open(AddToFolder, { studyId, folderIds })
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.add-to-folder.dialog {
	display: flex;
	flex-direction: column;
	padding: 0;
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	@media (min-width: $mqSmall) {
		width: 400px;
		max-width: 90vw;
		height: auto;
		max-height: 90vh;
	}
	.dialog-body {
		overflow-y: auto;
		padding: 16px;
		background: var(--primary-bg);
		border: 1px solid var(--secondary-border);
	}
	.folders li {
		display: flex;
		align-items: center;
		padding: 8px 0;
		&.child-folder {
			padding-left: 24px;
		}
	}
}
</style>
