


























































































import { openReportPdf } from '@dialogs/ReportPdf.vue'
import { mapActions } from 'vuex'
import consultations from '@services/consultationService'
import UndoRedo from '@/reporting/components/UndoRedo.vue'

export default {
	name: 'RelatedStudies',
	props: {
		relatedStudies: {
			type: Array,
			default: () => [],
		},
		reports: {
			type: Array,
			default: () => [],
		},
	},
  data: function() {
    return {
      IDEXXAPI : this.$IDEXXAPI,
			VetsChoice_ClinicCode : this.$VetsChoice_ClinicCode
    }
  },
  created() {

  },
	methods: {
		...mapActions(['openStudy']),
		openReport(report: IReportInfo) {
			if (report.isSaleSubmission) {
				this.$router.push({ name: 'sale-submission', params: { id: report.consultantReportId } })
			} else if (report.consultantReportId && report.isImageOnly) {
				this.$router.push({ name: 'image-only-report', params: { id: report.consultantReportId } })
			} else if (report.consultantReportId) {
				this.$router.push({ name: 'teleconsultation', params: { id: report.consultantReportId } })
			} else {
				this.$router.push({ name: 'standard-report', params: { id: report.reportId } })
			}
		},
		openReportPdf(report: IReportInfo) {
			const { reportId, consultantReportId, isComplete } = report
			openReportPdf(reportId, consultantReportId, isComplete)
		},
		async downloadExternalReport(providerClinicCode: string, consultantReportId: string) {
			var clientClinicCode = this.$store.state.auth.claims.activeClinicCode
      var getReportResult = await consultations.downloadExternalReport(providerClinicCode, clientClinicCode, consultantReportId)

			if (getReportResult['Success']){
				var reportBinaryString = getReportResult['Data']
				let pdfWindow = window.open("")
				pdfWindow.document.write(
    			"<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
    			encodeURI(reportBinaryString) + "'></iframe>"
				)
			}
		}
	},
}
