<template>
	<modal-wrapper @close="close()">
		<div class="delete-images-modal">
			<header>
				<ast-button
					v-if="activeModal !== modals.DELETE_STUDY_OR_IMAGES && hasMultipleSeries"
					class="back"
					@click.native="goBack"
				>
					<svg-icon icon="arrow-left" />
				</ast-button>
				<h4>Delete Images</h4>
				<button class="close" @click="close()">
					<svg-icon icon="close" />
				</button>
			</header>
			<div class="delete-images-modal-body">
				<!-- Study or images -->
				<div v-if="activeModal === modals.DELETE_STUDY_OR_IMAGES">
					<p>
						Would you like to delete the entire study or only some of the images?
					</p>
					<div class="buttons">
						<ast-button @click.native="activeModal = modals.CONFIRM_STUDY">
							Entire Study
						</ast-button>
						<ast-button @click.native="activeModal = modals.THUMBNAILS">
							Individual Images
						</ast-button>
						<ast-button @click.native="close()">
							Cancel
						</ast-button>
					</div>
				</div>

				<!-- Confirm deleting study -->
				<div v-if="activeModal === modals.CONFIRM_STUDY">
					<p>
						Are you sure you want to delete this study?
					</p>
					<div class="buttons">
						<ast-button type="danger" :disabled="isDeleting" @click.native="deleteStudy()">
							<span v-if="!isDeleting">Yes</span>
							<svg-icon v-else icon="spinner" pulse />
						</ast-button>
						<ast-button :disabled="isDeleting" @click.native="goBack">
							No
						</ast-button>
					</div>
				</div>

				<!-- Confirm deleting attachment -->
				<div v-if="activeModal === modals.CONFIRM_ATTACHMENT">
					<p>
						Are you sure you want to delete
						<strong>{{ selectedThumbnail.description || selectedThumbnail.friendlyName }}</strong>
						?
					</p>
					<div class="buttons">
						<ast-button type="danger" :disabled="isDeleting" @click.native="deleteSeries(true)">
							<span v-if="!isDeleting">Yes</span>
							<svg-icon v-else icon="spinner" pulse />
						</ast-button>
						<ast-button :disabled="isDeleting" @click.native="goBack">
							No
						</ast-button>
					</div>
				</div>

				<!-- Confirm deleting image -->
				<div v-if="activeModal === modals.CONFIRM_IMAGE">
					<p>
						Are you sure you want to delete this image?
					</p>
					<div
						class="thumbnail"
						alt="Thumbnail preview of first image in series"
						style="width: 125px; margin: auto; padding-bottom: 8px;"
						:style="{
							'background-image': `url(
                ${findThumbnailUrl(selectedThumbnail)}
              )`,
						}"
					></div>
					<div class="thumbnail-label" style="padding: 8px 0;">
						<strong v-if="selectedThumbnail.seriesNumber">
							{{ selectedThumbnail.seriesNumber }}
						</strong>
						<span v-if="selectedThumbnail.simpleName" class="thumbnail-name">
							{{ selectedThumbnail.simpleName }}
						</span>
						<p v-if="selectedThumbnail.numberOfImages > 1" class="thumbnail-images">
							{{ selectedThumbnail.numberOfImages }}
						</p>
					</div>
					<div class="buttons">
						<ast-button type="danger" :disabled="isDeleting" @click.native="deleteSeries()">
							<span v-if="!isDeleting">Yes</span>
							<svg-icon v-else icon="spinner" pulse />
						</ast-button>
						<ast-button :disabled="isDeleting" @click.native="goBack">
							No
						</ast-button>
					</div>
				</div>

				<div v-if="activeModal === modals.THUMBNAILS" style="min-width: 300px;">
					<!-- Thumbnails -->
					<ol v-if="thumbnails.length" class="thumbnails">
						<li v-for="(thumbnail, i) in thumbnails" :key="'thumbnail-' + i">
							<div
								class="thumbnail"
								alt="Thumbnail preview of first image in series"
								:style="{
									'background-image': `url(
										${findThumbnailUrl(thumbnail)}
									)`,
								}"
							>
								<button class="series-delete-button" title="Delete Image" @click="selectThumbnail(thumbnail)">
									<div class="delete-circle">
										<svg-icon icon="delete" style="margin: 0;" />
									</div>
								</button>
							</div>
							<div class="thumbnail-label">
								<strong v-if="thumbnail.seriesNumber">{{ thumbnail.seriesNumber }}</strong>
								<span v-if="thumbnail.simpleName" class="thumbnail-name">
									{{ thumbnail.simpleName }}
								</span>
								<p v-if="thumbnail.numberOfImages > 1" class="thumbnail-images">
									{{ thumbnail.numberOfImages }}
								</p>
							</div>
						</li>
					</ol>
					<!-- Attachments -->
					<ol v-if="attachments.length" class="attachments">
						<li v-for="file in attachments" :key="file.imageId">
							<div class="attachment">
								<svg-icon :icon="findFileIconName(file.fileExtension)" style="padding-right: 8px;" />
								<span>
									{{ file.description || file.friendlyName }}
								</span>
								<button class="series-delete-button" title="Delete Image" @click="selectAttachment(file)">
									<div class="delete-circle">
										<svg-icon icon="delete" style="margin: 0;" />
									</div>
								</button>
							</div>
						</li>
					</ol>
				</div>
				<div v-if="isLoading && !isDeleting" class="loading">
					<svg-icon icon="spinner" pulse class="is-muted" />
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import api from '@services/api'
import { findThumbnailUrl } from '@utils/urlUtility'
import ModalWrapper from '@components/ModalWrapper'
import AstButton from '@components/Button'
import { dlg } from '@utils/dlgUtils'
import { FileUtils } from '@utils/fileUtils'

const modals = {
	DELETE_STUDY_OR_IMAGES: 0,
	THUMBNAILS: 1,
	CONFIRM_STUDY: 2,
	CONFIRM_IMAGE: 3,
	CONFIRM_ATTACHMENT: 4,
}

const DeleteImagesDlg = {
	name: 'DeleteImagesDlg',
	components: {
		ModalWrapper,
		AstButton,
	},
	props: {
		studyId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			modals,
			activeModal: modals.DELETE_STUDY_OR_IMAGES,
			selectedThumbnail: null,
			isLoading: true,
			isDeleting: false,
			study: {},
			didDelete: false,
		}
	},
	computed: {
		attachments() {
			return this.study.imageData && this.study.imageData.attachments
		},
		thumbnails() {
			return this.study.imageData && this.study.imageData.thumbnails
		},
		hasMultipleSeries() {
			return this.attachments.length + this.thumbnails.length > 1
		},
	},
	watch: {
		studyId: {
			handler() {
				this.getStudy()
			},
			immediate: true,
		},
	},
	methods: {
		findThumbnailUrl,
		findFileIconName: FileUtils.findFileIconName,
		async getStudy() {
			this.isLoading = true
			try {
				const { studies } = await this.$api.viewer.getStudy({ ids: [this.studyId] }, false)
				if (studies && studies.length) this.study = studies[0]
				// if study only has one series (or none), skip straight to the delete-study confirmation
				if (this.activeModal === modals.DELETE_STUDY_OR_IMAGES && !this.hasMultipleSeries) {
					this.activeModal = modals.CONFIRM_STUDY
				}
			} finally {
				this.isLoading = false
			}
		},
		async deleteStudy() {
			this.isDeleting = true
			try {
				await api.study.deleteStudy(this.studyId)
				this.didDelete = true
				this.close()
			} finally {
				this.isDeleting = false
			}
		},
		async deleteSeries(isAttachment) {
			if (!this.hasMultipleSeries) return this.deleteStudy() // if on last series, delete the study
			this.isDeleting = true
			try {
				const arrayToSearch = isAttachment ? this.attachments : this.study.imageData.series
				const selectedSeries = arrayToSearch.find(s => s.seriesId === this.selectedThumbnail.seriesId)
				const { isFakeSeries } = selectedSeries
				await api.study.deleteSeries({
					imageId: isFakeSeries ? this.selectedThumbnail.imageId : null,
					seriesId: isFakeSeries ? null : this.selectedThumbnail.seriesId,
					studyId: this.studyId,
				})
				this.didDelete = true
				await this.getStudy()
				this.goBack()
				this.selectedThumbnail = null
			} finally {
				this.isDeleting = false
			}
		},
		close() {
			dlg.close(this, true, this.didDelete)
		},
		selectThumbnail(thumbnail) {
			this.selectedThumbnail = thumbnail
			this.activeModal = modals.CONFIRM_IMAGE
		},
		selectAttachment(file) {
			this.selectedThumbnail = file
			this.activeModal = modals.CONFIRM_ATTACHMENT
		},
		goBack() {
			// if the last series was deleted, close
			if (!this.study.imageData.series.length) return this.close()
			// if cancelling deleting single-image study, close
			if (!this.hasMultipleSeries) return this.close()
			if ([modals.THUMBNAILS, modals.CONFIRM_STUDY].includes(this.activeModal)) {
				this.activeModal = modals.DELETE_STUDY_OR_IMAGES
			}
			if ([modals.CONFIRM_IMAGE, modals.CONFIRM_ATTACHMENT].includes(this.activeModal)) {
				this.activeModal = modals.THUMBNAILS
			}
		},
	},
}

export default DeleteImagesDlg

export function openDeleteImagesDlg({ studyId }) {
	return dlg.open(DeleteImagesDlg, { studyId })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.delete-images-modal {
	display: flex;
	flex-direction: column;
	background: var(--secondary-bg);
	width: 100vw;
	height: 100vh;
	height: -webkit-fill-available;
	@media (min-width: $mqSmall) {
		width: auto;
		height: auto;
		max-width: 80vw;
		max-height: 80vh;
	}
}
header {
	min-height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-bottom: 1px solid var(--primary-border);
	.close,
	.back {
		position: absolute;
		top: 0;
		border: 0;
		cursor: pointer;
		background: transparent;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 48px;
		height: 48px;
	}
	.back {
		left: 0;
	}
	.close {
		right: 0;
	}
}
.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--secondary-bg);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3em;
}
.delete-images-modal-body {
	position: relative;
	overflow-y: auto;
	p {
		text-align: center;
		padding: 16px;
	}
	.buttons {
		text-align: center;
		margin-bottom: 16px;
		button {
			min-width: 64px;
			margin: 4px;
		}
	}
}
.attachments,
.thumbnails {
	list-style-type: none;
	padding: 16px;
	li {
		margin: 8px;
	}
}
.attachments li {
	padding: 0 8px;
	&:nth-child(even) {
		background: var(--table-row-odd-bg);
	}
}
.attachments .attachment {
	height: 40px;
	display: inline-flex;
	align-items: center;
	padding-right: 40px;
	position: relative;
}
.thumbnails li {
	vertical-align: top;
	width: 125px;
	display: inline-block;
	position: relative;
}
.thumbnail {
	height: 125px;
	background-size: contain;
	background-color: black;
	background-position: center;
	background-repeat: no-repeat;
}
.thumbnail-label,
strong,
.thumbnail-images {
	font-size: 0.9em;
	text-align: center;
	word-break: break-word;
	hyphens: auto;
}
strong + .thumbnail-name:before {
	content: ': ';
}
.series-delete-button {
	position: absolute;
	top: 0;
	right: 0;
	background: transparent;
	border: 0;
	outline: 0;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	.delete-circle {
		border-radius: 100%;
		padding: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--button-danger-bg);
		color: var(--button-danger-color);
	}
	&:hover .delete-circle {
		background: var(--button-danger-bg-hover);
		color: var(--button-danger-color-hover);
	}
}
</style>
