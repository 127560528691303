













































































































































































import { mapState } from 'vuex'
import AstButton from '@components/Button.vue'
import ButtonDropdown from '@components/ButtonDropdown.vue'
import ToolbarMenu from '@components/ToolbarMenu.vue'
import AstEmailLog from '@components/view/StudiesListEmailLog.vue'
import AstListRelatedStudies from '@components/view/StudiesListExpandedStudyRelatedStudies.vue'
import AstStudyThumbnails from '@components/view/StudiesListDetailThumbnails.vue'
import AttachmentList from '@components/AttachmentList.vue'
import StudyFolders from '@components/view/StudiesListExpandedStudyFolders.vue'
import { EmailItem } from '@components/EmailStudyForm.vue'
import { openEmailStudyDlg } from '@dialogs/emailStudyDlg.vue'
import { openAttachFileDlg } from '@dialogs/AttachFileDlg.vue'
import { openRenameStudyDlg } from '@dialogs/RenameStudy.vue'
import { openAnonymizeStudyDlg } from '@dialogs/AnonymizeStudy.vue'
import { openDicomSendDlg } from '@dialogs/DicomSendDlg.vue'
import { studyData } from '@services/studyData'
import { studyFolders } from '@services/folders'
import { eventBus } from '@services/eventBus'
import { SIGNALPET_IMAGEVIEW_ID } from '@services/dicomPartners'
import { userData } from '@services/userData'
import { openScheduleItemDlg } from '@/schedule/dialogs/ScheduleItemDlg.vue'
import wsData from '@services/wsData'
import consultations from "@services/consultationService";
import { showWaitDlg, closeWaitDlg } from "@dialogs/WaitForProcessDlg.vue"
import { openTimeMessage } from "@dialogs/OneTimeMessage.dialog.vue"
import { openTab } from "@services/openWindow"
import GoogleAnalytics from '@services/analyticsService'

export default {
	name: 'ExpandedStudy',
	components: {
		AstButton,
		ButtonDropdown,
		ToolbarMenu,
		AstEmailLog,
		AstListRelatedStudies,
		AstStudyThumbnails,
		AttachmentList,
		StudyFolders,
	},
	props: {
		studyId: {
			type: String,
			required: true,
		},
		folderId: {
			type: String,
			default: null,
		},
		showThumbnails: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			study: null,
			studyThumbnails: [],
			attachments: [],
			emails: [],
			emailItems: [],
			isLoading: true,
			isDeleted: false,
			reports: [],
			relatedStudies: [],
			folderIds: [],
			IDEXXAPI: this.$IDEXXAPI
		}
	},
	computed: {
		...mapState({
			permissions: (state: any) => state.static.permissions,
			consultants: state => state.static.consultants,
		}),
		hasSignalPETAttachment() {
			return this.attachments.find(a => a.imageViewId === SIGNALPET_IMAGEVIEW_ID)
		},
		canCreateScheduleItem() {
			return userData.canCreateScheduleItem
		},
		removedFromCurrentFolder() {
			return this.folderId && !this.folderIds.includes(this.folderId)
		},
		showIdexxButton() {
			return this.consultants.filter(x => x.clinicCode === this.IDEXXAPI).length > 0
		}
	},
	watch: {
		removedFromCurrentFolder() {
			this.$emit('removed-from-folder', this.removedFromCurrentFolder)
		},
	},
	created() {
		eventBus.on('add-study-to-folder', this.onAddStudyToFolder)
		wsData.subscribe(this.studyId, this.getStudy)
	},
	async mounted() {
		this.isLoading = true
		this.$emit('study-loading', this.isLoading)
		try {
			await Promise.all([
				this.getEmails(),
				this.getStudy(),
				this.getRelatedStudiesAndReports(),
				this.getFolderIdsForStudy(),
			])
		} finally {
			this.isLoading = false
			this.$emit('study-loading', this.isLoading)
		}
	},
	beforeDestroy() {
		eventBus.off('add-study-to-folder', this.onAddStudyToFolder)
		wsData.unsubscribeByCallback(this.getStudy)
		if (this.removedFromCurrentFolder) this.$emit('remove', this.studyId)
	},
	methods: {
		async idexx() {
			// Get the IDEXX consultantId
			// This assumes the IDEXX Teleconsultation consultant is added into the consultants list
			const idexxData = this.consultants.filter(x => x.clinicCode === this.IDEXXAPI)
			if (idexxData.length > 0) {
				showWaitDlg("Please wait...", "Creating Case", true)
				const reportData = await consultations.startRequestReport(this.study.studyId, idexxData[0].id)
				const request: INewConsultationRequest = {
					consultantId: reportData.consultant.id,
					selectedConsultantId: '',
					priority: 1,
					serviceId: '',
					billingCodeId: '',
					studyIds: [this.studyId],
					seriesToExclude: [],
					imagesToExclude: [],
					requestType: '',
					patient: reportData.patient,
					client: reportData.client,
					emails: this.emails,
					templateId: reportData.request.templateId,
					templateFieldId: '',
					values: {},
					modality: this.study?.modality
				}
				const consultCase = await consultations.createCase(request)
				closeWaitDlg()
				if (consultCase.data['Success']) {
					openTimeMessage("IDEXX VMS Case Created", "Images may take some time to upload. You can monitor the status in IDEXX VetMedStat.")
					let success = openTab(consultCase.data['Data'])
					if (success.error) {
						const notification = {
							message: success.errorMessage,
							notificationType: 'error'
						}
						await this.$store.dispatch('addNotification', notification)
					}
				}
			}

		},
		newExam() {
			openScheduleItemDlg(null, this.study)
		},
		updateFolders(folderIds) {
			this.folderIds = folderIds
		},
		onAddStudyToFolder(studyId, folderId) {
			// if this study was added to a folder via drag, add the folder to this view
			if (studyId === this.studyId) this.folderIds.push(folderId)
		},
		getEmailImageList(includeAttachments = false) {
			return this.getEmailItems(includeAttachments)
		},
		handleThumbnailClick: function (thumbnail) {
			this.$emit('thumbnail-click', thumbnail)
		},
		async getStudy() {
			const data = await this.$api.viewer.getStudy({ ids: this.studyId })
			if (data && data.studies && data.studies.length) {
				const study = data.studies[0]
				this.study = study
				this.studyThumbnails = study.imageData.thumbnails
				this.emailItems = this.getEmailItems()
				this.attachments = study.imageData.attachments.slice()
			} else if (data && data.deletedStudies && data.deletedStudies.length) {
				this.isDeleted = true
			}
		},
		async getEmails() {
			const response = await this.$api.study.getEmails(this.studyId)
			if (response) {
				this.emails = response.data
			} else {
				this.emails = []
			}
		},
		async getRelatedStudiesAndReports() {
			const data = await studyData.getRelatedStudiesAndReports(this.studyId)
			if (!data) return
			this.relatedStudies = data.studies

			this.reports = data.reports
			for (const report of this.reports) {

				const { reportId, consultantReportId, isComplete } = report
				if (consultantReportId !== null) {
					let consultantReport = await consultations.getConsultantReport(consultantReportId)
					report.consultantClinicCode = consultantReport.consultant.clinicCode

					if (consultantReport.consultant.clinicCode === this.$VetCTAPI) {
						var vetCTCase = await this.getThirdPartyRadiologyReport(report)
						if (vetCTCase !== undefined && vetCTCase["Success"] === true && vetCTCase["Message"]) {
							report.isVetCT = true
							report.thirdPartyCaseLink = vetCTCase.Message
							report.vetCTCaseStatus = vetCTCase.Data
						}
					}
					else if (consultantReport.consultant.clinicCode === this.$VetsChoice_ClinicCode && report.isComplete) {
						var thirdPartyReportResult = await this.getThirdPartyRadiologyReport(report)
						if (thirdPartyReportResult !== undefined && thirdPartyReportResult["Success"] === true && thirdPartyReportResult["Message"]) {
							report.thirdPartyCaseLink = thirdPartyReportResult.Message
						}
					}
				}
			}
		},
		async getThirdPartyRadiologyReport(report: IReportInfo) {
			var result
			const { reportId, consultantReportId, isComplete } = report
			if (consultantReportId !== null) {
				let consultantReport = await consultations.getConsultantReport(consultantReportId)
				result = await consultations.getCase(consultantReport.consultant.clinicCode, consultantReportId)
				this.thirdPartyCaseLink = result.Message
				this.vetCTCaseStatus = result.Data
			}
			return result
		},
		async getFolderIdsForStudy() {
			this.folderIds = await studyFolders.getFolderIdsForStudy(this.studyId)
		},
		showArchivingTip: studyData.showArchivingTip,
		openAttachment(seriesId) {
			this.$emit('action', { action: 'view', seriesId })
		},
		gotoSignalPET() {
			this.$router.push({
				name: 'signalpet',
				params: {
					studyId: this.study.studyId,
					clinicCode: this.study.clinicCode,
				},
			})
		},
		openDicomSendDlg() {
			let items = this.getEmailImageList(false)
			openDicomSendDlg(this.study, items)
		},
		openEmailDlg() {
			let items = this.getEmailImageList(true)
			openEmailStudyDlg([this.study], items)
				.then(() => {
					this.getEmails()
				})
				.catch(e => { })
		},
		async attachFile() {
			let attached = await openAttachFileDlg(this.study)
			if (attached) {
				this.getStudy()
			}
		},
		anonymizeStudy() {
			openAnonymizeStudyDlg(this.study)
		},
		async renameStudy() {
			const study = await openRenameStudyDlg(this.study)
			if (study) {
				this.$store.dispatch('addNotification', {
					message: 'Study renamed successfully!',
					notificationType: 'success',
				})
				try {
					GoogleAnalytics.sendGAEvent('Study', {
						event_category: 'Rename',
						event_label: 'Rename'
					})
				}
				catch (err) {
					console.log(err)
				}
				this.$emit('rename-success', study)
			}
		},
		getEmailItems(includeAttachments = false) {
			const result = this.study.imageData.series.map(s => new EmailItem(s, s.images[0], !s.isFakeSeries))
			if (includeAttachments) {
				this.study.imageData.attachments.forEach(a => {
					result.push(EmailItem.fromAttachment(a, this.studyId))
				})
			}
			return result
		},
	},
}
