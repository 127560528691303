





























































import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import scheduleData from '@services/scheduleData'
import { openScheduleModalityDlg } from './ScheduleModalityDlg.vue'

const ScheduleModalitiesDlg = {
	name: 'ScheduleModalitiesDlg',
	components: {
		DlgHeader,
		ModalWrapper,
	},
	data() {
		return {
			dragOverIndex: null,
			items: [],
		}
	},
	async created() {
		this.items = await scheduleData.getModalities()
	},
	methods: {
		moveItem({ from, to }) {
			this.items.splice(to, 0, this.items.splice(from, 1)[0])
		},
		moveItemUp(i) {
			this.moveItem({ from: i, to: i - 1 })
		},
		moveItemDown(i) {
			this.moveItem({ from: i, to: i + 1 })
		},
		async deleteItem(item) {
			if (!(await showConfirm(`Are you sure you want to delete ${item.name}?`))) return
			item.deleted = true
			await scheduleData.saveModality(item)

			let i = this.items.indexOf(item)
			this.items.splice(i, 1)
		},
		async addNewItem() {
			let item = await openScheduleModalityDlg(null)
			if (!item) return
			this.items.push(item)
		},
		async editItem(item) {
			let changedItem = await openScheduleModalityDlg(item)
			if (!changedItem) return
			let idx = this.items.indexOf(item)
			if (changedItem.deleted) {
				this.items.splice(idx, 1)
			} else {
				this.items.splice(idx, 1, changedItem)
			}
		},
		onDragOver(e, i) {
			e.dataTransfer.dropEffect = 'move'
		},
		onDragEnter(e, i) {
			e.target.classList.add(i < this.draggingIndex ? 'drop-before' : 'drop-after')
			this.dragOverIndex = i
		},
		onDragLeave(e) {
			e.target.classList.remove('drop-before', 'drop-after')
			this.dragOverIndex = null
		},
		onDrop(e, i) {
			e.target.classList.remove('drop-before', 'drop-after')
			this.moveItem({ from: this.draggingIndex, to: i })
		},
		onDragStart(e, i) {
			this.draggingIndex = i
		},
		onDragEnd() {
			this.draggingIndex = null
			this.dragOverIndex = null
		},
		async save() {
			let changed: IScheduleModality[] = []
			let items: IScheduleModality[] = this.items
			for (let i = 0; i < items.length; i++) {
				let item = items[i]
				if (item.displaySequence !== i) {
					item.displaySequence = i
					changed.push(item)
				}
			}

			await scheduleData.saveModalities(changed)
			this.close()
		},
		async close() {
			let items = await scheduleData.getModalities()
			dlg.close(this, true, items)
		},
	},
}

export default ScheduleModalitiesDlg

export function openScheduleModalities() {
	return dlg.open(ScheduleModalitiesDlg, {})
}
